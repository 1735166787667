/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    DeviceDeviceGroupDTO,
    DeviceDeviceGroupDTOFromJSON,
    DeviceDeviceGroupDTOToJSON,
    DeviceGroup,
    DeviceGroupFromJSON,
    DeviceGroupToJSON,
    DeviceGroupDTO,
    DeviceGroupDTOFromJSON,
    DeviceGroupDTOToJSON,
    DeviceGroupPostDTO,
    DeviceGroupPostDTOFromJSON,
    DeviceGroupPostDTOToJSON,
    DeviceGroupUpdateDTO,
    DeviceGroupUpdateDTOFromJSON,
    DeviceGroupUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteDeviceFromDeviceGroupRequest {
    xTenantId?: string;
    deviceDeviceGroupDTO?: DeviceDeviceGroupDTO;
}

export interface DeleteDeviceGroupRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceGroupRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceGroupSuggestionsRequest {
    xTenantId?: string;
}

export interface GetDeviceGroupsRequest {
    xTenantId?: string;
}

export interface PostDeviceGroupRequest {
    xTenantId?: string;
    deviceGroupPostDTO?: DeviceGroupPostDTO;
}

export interface PostDeviceToDeviceGroupRequest {
    xTenantId?: string;
    deviceDeviceGroupDTO?: DeviceDeviceGroupDTO;
}

export interface PutDeviceGroupRequest {
    id: string;
    xTenantId?: string;
    deviceGroupUpdateDTO?: DeviceGroupUpdateDTO;
}

/**
 * DeviceGroupApi - interface
 * 
 * @export
 * @interface DeviceGroupApiInterface
 */
export interface DeviceGroupApiInterface {
    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceDeviceGroupDTO} [deviceDeviceGroupDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    deleteDeviceFromDeviceGroupRaw(requestParameters: DeleteDeviceFromDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceDeviceGroupDTO>>;

    /**
     */
    deleteDeviceFromDeviceGroup(requestParameters: DeleteDeviceFromDeviceGroupRequest): Promise<DeviceDeviceGroupDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    deleteDeviceGroupRaw(requestParameters: DeleteDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroup>>;

    /**
     */
    deleteDeviceGroup(requestParameters: DeleteDeviceGroupRequest): Promise<DeviceGroup>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    getDeviceGroupRaw(requestParameters: GetDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroupDTO>>;

    /**
     */
    getDeviceGroup(requestParameters: GetDeviceGroupRequest): Promise<DeviceGroupDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    getDeviceGroupSuggestionsRaw(requestParameters: GetDeviceGroupSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     */
    getDeviceGroupSuggestions(requestParameters: GetDeviceGroupSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    getDeviceGroupsRaw(requestParameters: GetDeviceGroupsRequest): Promise<runtime.ApiResponse<Array<DeviceGroupDTO>>>;

    /**
     */
    getDeviceGroups(requestParameters: GetDeviceGroupsRequest): Promise<Array<DeviceGroupDTO>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceGroupPostDTO} [deviceGroupPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    postDeviceGroupRaw(requestParameters: PostDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroupDTO>>;

    /**
     */
    postDeviceGroup(requestParameters: PostDeviceGroupRequest): Promise<DeviceGroupDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceDeviceGroupDTO} [deviceDeviceGroupDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    postDeviceToDeviceGroupRaw(requestParameters: PostDeviceToDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceDeviceGroupDTO>>;

    /**
     */
    postDeviceToDeviceGroup(requestParameters: PostDeviceToDeviceGroupRequest): Promise<DeviceDeviceGroupDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceGroupUpdateDTO} [deviceGroupUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceGroupApiInterface
     */
    putDeviceGroupRaw(requestParameters: PutDeviceGroupRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    putDeviceGroup(requestParameters: PutDeviceGroupRequest): Promise<void>;

}

/**
 * 
 */
export class DeviceGroupApi extends runtime.BaseAPI implements DeviceGroupApiInterface {

    /**
     */
    async deleteDeviceFromDeviceGroupRaw(requestParameters: DeleteDeviceFromDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceDeviceGroupDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/device`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceDeviceGroupDTOToJSON(requestParameters.deviceDeviceGroupDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDeviceGroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async deleteDeviceFromDeviceGroup(requestParameters: DeleteDeviceFromDeviceGroupRequest): Promise<DeviceDeviceGroupDTO> {
        const response = await this.deleteDeviceFromDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteDeviceGroupRaw(requestParameters: DeleteDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupFromJSON(jsonValue));
    }

    /**
     */
    async deleteDeviceGroup(requestParameters: DeleteDeviceGroupRequest): Promise<DeviceGroup> {
        const response = await this.deleteDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceGroupRaw(requestParameters: GetDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroupDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async getDeviceGroup(requestParameters: GetDeviceGroupRequest): Promise<DeviceGroupDTO> {
        const response = await this.getDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceGroupSuggestionsRaw(requestParameters: GetDeviceGroupSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     */
    async getDeviceGroupSuggestions(requestParameters: GetDeviceGroupSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getDeviceGroupSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceGroupsRaw(requestParameters: GetDeviceGroupsRequest): Promise<runtime.ApiResponse<Array<DeviceGroupDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceGroupDTOFromJSON));
    }

    /**
     */
    async getDeviceGroups(requestParameters: GetDeviceGroupsRequest): Promise<Array<DeviceGroupDTO>> {
        const response = await this.getDeviceGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postDeviceGroupRaw(requestParameters: PostDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroupDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceGroupPostDTOToJSON(requestParameters.deviceGroupPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async postDeviceGroup(requestParameters: PostDeviceGroupRequest): Promise<DeviceGroupDTO> {
        const response = await this.postDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postDeviceToDeviceGroupRaw(requestParameters: PostDeviceToDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceDeviceGroupDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/device`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceDeviceGroupDTOToJSON(requestParameters.deviceDeviceGroupDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDeviceGroupDTOFromJSON(jsonValue));
    }

    /**
     */
    async postDeviceToDeviceGroup(requestParameters: PostDeviceToDeviceGroupRequest): Promise<DeviceDeviceGroupDTO> {
        const response = await this.postDeviceToDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async putDeviceGroupRaw(requestParameters: PutDeviceGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/DeviceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceGroupUpdateDTOToJSON(requestParameters.deviceGroupUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putDeviceGroup(requestParameters: PutDeviceGroupRequest): Promise<void> {
        await this.putDeviceGroupRaw(requestParameters);
    }

}
