/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    RuleNode,
    RuleNodeFromJSON,
    RuleNodeFromJSONTyped,
    RuleNodeToJSON,
    RuleNodeRelation,
    RuleNodeRelationFromJSON,
    RuleNodeRelationFromJSONTyped,
    RuleNodeRelationToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * Kural listesi
 * @export
 * @interface RuleChain
 */
export interface RuleChain {
    /**
     * 
     * @type {Date}
     * @memberof RuleChain
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof RuleChain
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof RuleChain
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    id?: string;

    /**
     * 
     * @type {Tenant}
     * @memberof RuleChain
     */
    tenant?: Tenant;

    /**
     * Rulechain iÃ§in ayarlar.
     * @type {any}
     * @memberof RuleChain
     */
    _configuration?: any | null;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    name?: string | null;

    /**
     * True olanlara gelen sensor verisi verilir. False olanlar baÅŸka bir chainden mesaj bekler
     * @type {boolean}
     * @memberof RuleChain
     */
    rootChain?: boolean;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    firstNodeId?: string;

    /**
     * 
     * @type {Array<RuleNode>}
     * @memberof RuleChain
     */
    ruleNodes?: Array<RuleNode> | null;

    /**
     * 
     * @type {Array<RuleNodeRelation>}
     * @memberof RuleChain
     */
    ruleNodeRelations?: Array<RuleNodeRelation> | null;

    /**
     * 
     * @type {string}
     * @memberof RuleChain
     */
    tenantId?: string;

}

export function RuleChainFromJSON(json: any): RuleChain {
    return RuleChainFromJSONTyped(json, false);
}

export function RuleChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleChain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rootChain': !exists(json, 'rootChain') ? undefined : json['rootChain'],
        'firstNodeId': !exists(json, 'firstNodeId') ? undefined : json['firstNodeId'],
        'ruleNodes': !exists(json, 'ruleNodes') ? undefined : (json['ruleNodes'] === null ? null : (json['ruleNodes'] as Array<any>).map(RuleNodeFromJSON)),
        'ruleNodeRelations': !exists(json, 'ruleNodeRelations') ? undefined : (json['ruleNodeRelations'] === null ? null : (json['ruleNodeRelations'] as Array<any>).map(RuleNodeRelationFromJSON)),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function RuleChainToJSON(value?: RuleChain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'tenant': TenantToJSON(value.tenant),
        'configuration': value._configuration,
        'name': value.name,
        'rootChain': value.rootChain,
        'firstNodeId': value.firstNodeId,
        'ruleNodes': value.ruleNodes === undefined ? undefined : (value.ruleNodes === null ? null : (value.ruleNodes as Array<any>).map(RuleNodeToJSON)),
        'ruleNodeRelations': value.ruleNodeRelations === undefined ? undefined : (value.ruleNodeRelations === null ? null : (value.ruleNodeRelations as Array<any>).map(RuleNodeRelationToJSON)),
        'tenantId': value.tenantId,
    };
}


