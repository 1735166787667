/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    ClaimDTO,
    ClaimDTOFromJSON,
    ClaimDTOFromJSONTyped,
    ClaimDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface RolePostDTO
 */
export interface RolePostDTO {
    /**
     * 
     * @type {string}
     * @memberof RolePostDTO
     */
    name?: string | null;

    /**
     * 
     * @type {Array<ClaimDTO>}
     * @memberof RolePostDTO
     */
    claims?: Array<ClaimDTO> | null;

    /**
     * 
     * @type {Array<string>}
     * @memberof RolePostDTO
     */
    permissions?: Array<string> | null;

}

export function RolePostDTOFromJSON(json: any): RolePostDTO {
    return RolePostDTOFromJSONTyped(json, false);
}

export function RolePostDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RolePostDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(ClaimDTOFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function RolePostDTOToJSON(value?: RolePostDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(ClaimDTOToJSON)),
        'permissions': value.permissions,
    };
}


