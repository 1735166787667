/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface CommandToDevicePostDTO
 */
export interface CommandToDevicePostDTO {
    /**
     * 
     * @type {string}
     * @memberof CommandToDevicePostDTO
     */
    sender?: string | null;

    /**
     * 
     * @type {Date}
     * @memberof CommandToDevicePostDTO
     */
    timestamp?: moment.Moment | null;

    /**
     * 
     * @type {string}
     * @memberof CommandToDevicePostDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {string}
     * @memberof CommandToDevicePostDTO
     */
    command?: string | null;

    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CommandToDevicePostDTO
     */
    params?: { [key: string]: any; } | null;

}

export function CommandToDevicePostDTOFromJSON(json: any): CommandToDevicePostDTO {
    return CommandToDevicePostDTOFromJSONTyped(json, false);
}

export function CommandToDevicePostDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommandToDevicePostDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (json['timestamp'] === null ? null : moment(json['timestamp'])),

        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'command': !exists(json, 'command') ? undefined : json['command'],
        'params': !exists(json, 'params') ? undefined : json['params'],
    };
}

export function CommandToDevicePostDTOToJSON(value?: CommandToDevicePostDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender': value.sender,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp === null ? null : value.timestamp.toISOString()),
        'deviceId': value.deviceId,
        'command': value.command,
        'params': value.params,
    };
}


