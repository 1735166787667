/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    CommandLogDTO,
    CommandLogDTOFromJSON,
    CommandLogDTOToJSON,
    CommandPostDTO,
    CommandPostDTOFromJSON,
    CommandPostDTOToJSON,
    DeviceStateDTO,
    DeviceStateDTOFromJSON,
    DeviceStateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    TimeSeriesDataDTOListPagedResponse,
    TimeSeriesDataDTOListPagedResponseFromJSON,
    TimeSeriesDataDTOListPagedResponseToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
    Vehicle,
    VehicleFromJSON,
    VehicleToJSON,
    VehicleDTO,
    VehicleDTOFromJSON,
    VehicleDTOToJSON,
    VehiclePostDTO,
    VehiclePostDTOFromJSON,
    VehiclePostDTOToJSON,
    VehicleTelemetryDTO,
    VehicleTelemetryDTOFromJSON,
    VehicleTelemetryDTOToJSON,
    VehicleTelemetrySdsDTOListPagedResponse,
    VehicleTelemetrySdsDTOListPagedResponseFromJSON,
    VehicleTelemetrySdsDTOListPagedResponseToJSON,
    VehicleUpdateDTO,
    VehicleUpdateDTOFromJSON,
    VehicleUpdateDTOToJSON,
} from '../models';

export interface DeleteVehicleRequest {
    id: string;
    xTenantId?: string;
}

export interface GetTotalVehicleCountRequest {
    xTenantId?: string;
}

export interface GetVehicleRequest {
    id: string;
    xTenantId?: string;
}

export interface GetVehicleCountRequest {
    xTenantId?: string;
}

export interface GetVehicleLatestTelemetryRequest {
    id: string;
    typeKey?: string;
    xTenantId?: string;
}

export interface GetVehicleStateRequest {
    id: string;
    xTenantId?: string;
}

export interface GetVehicleSuggestionsRequest {
    xTenantId?: string;
}

export interface GetVehicleTelemetryRequest {
    id: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    deviceId?: string;
    typeKeys?: Array<string>;
    deviceModelId?: string;
    pageNumber?: number;
    xTenantId?: string;
}

export interface GetVehicleTelemetrySdsRequest {
    id: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    pageNumber?: number;
    xTenantId?: string;
}

export interface GetVehiclesRequest {
    xTenantId?: string;
}

export interface PostCommandToVehicleRequest {
    id: string;
    xTenantId?: string;
    commandPostDTO?: CommandPostDTO;
}

export interface PostVehicleRequest {
    xTenantId?: string;
    vehiclePostDTO?: VehiclePostDTO;
}

export interface PutVehicleRequest {
    id: string;
    xTenantId?: string;
    vehicleUpdateDTO?: VehicleUpdateDTO;
}

/**
 * VehicleApi - interface
 * 
 * @export
 * @interface VehicleApiInterface
 */
export interface VehicleApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    deleteVehicleRaw(requestParameters: DeleteVehicleRequest): Promise<runtime.ApiResponse<Vehicle>>;

    /**
     */
    deleteVehicle(requestParameters: DeleteVehicleRequest): Promise<Vehicle>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getTotalVehicleCountRaw(requestParameters: GetTotalVehicleCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     */
    getTotalVehicleCount(requestParameters: GetTotalVehicleCountRequest): Promise<number>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleRaw(requestParameters: GetVehicleRequest): Promise<runtime.ApiResponse<VehicleDTO>>;

    /**
     */
    getVehicle(requestParameters: GetVehicleRequest): Promise<VehicleDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleCountRaw(requestParameters: GetVehicleCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     */
    getVehicleCount(requestParameters: GetVehicleCountRequest): Promise<number>;

    /**
     * 
     * @param {string} id 
     * @param {string} [typeKey] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleLatestTelemetryRaw(requestParameters: GetVehicleLatestTelemetryRequest): Promise<runtime.ApiResponse<VehicleTelemetryDTO>>;

    /**
     */
    getVehicleLatestTelemetry(requestParameters: GetVehicleLatestTelemetryRequest): Promise<VehicleTelemetryDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleStateRaw(requestParameters: GetVehicleStateRequest): Promise<runtime.ApiResponse<DeviceStateDTO>>;

    /**
     */
    getVehicleState(requestParameters: GetVehicleStateRequest): Promise<DeviceStateDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleSuggestionsRaw(requestParameters: GetVehicleSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     */
    getVehicleSuggestions(requestParameters: GetVehicleSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @param {string} id 
     * @param {Date} [startDate] Sorgu başlangıç tarihi
     * @param {Date} [endDate] Sorgu bitiş tarihi
     * @param {string} [deviceId] opsiyonel cihaz filtresi
     * @param {Array<string>} [typeKeys] opsiyonel timeseries veri filtresi
     * @param {string} [deviceModelId] opsiyonel cihaz model filtresi
     * @param {number} [pageNumber] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleTelemetryRaw(requestParameters: GetVehicleTelemetryRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>>;

    /**
     */
    getVehicleTelemetry(requestParameters: GetVehicleTelemetryRequest): Promise<TimeSeriesDataDTOListPagedResponse>;

    /**
     * 
     * @param {string} id 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {number} [pageNumber] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehicleTelemetrySdsRaw(requestParameters: GetVehicleTelemetrySdsRequest): Promise<runtime.ApiResponse<VehicleTelemetrySdsDTOListPagedResponse>>;

    /**
     */
    getVehicleTelemetrySds(requestParameters: GetVehicleTelemetrySdsRequest): Promise<VehicleTelemetrySdsDTOListPagedResponse>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    getVehiclesRaw(requestParameters: GetVehiclesRequest): Promise<runtime.ApiResponse<Array<VehicleDTO>>>;

    /**
     */
    getVehicles(requestParameters: GetVehiclesRequest): Promise<Array<VehicleDTO>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {CommandPostDTO} [commandPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    postCommandToVehicleRaw(requestParameters: PostCommandToVehicleRequest): Promise<runtime.ApiResponse<CommandLogDTO>>;

    /**
     */
    postCommandToVehicle(requestParameters: PostCommandToVehicleRequest): Promise<CommandLogDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {VehiclePostDTO} [vehiclePostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    postVehicleRaw(requestParameters: PostVehicleRequest): Promise<runtime.ApiResponse<VehicleDTO>>;

    /**
     */
    postVehicle(requestParameters: PostVehicleRequest): Promise<VehicleDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {VehicleUpdateDTO} [vehicleUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApiInterface
     */
    putVehicleRaw(requestParameters: PutVehicleRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    putVehicle(requestParameters: PutVehicleRequest): Promise<void>;

}

/**
 * 
 */
export class VehicleApi extends runtime.BaseAPI implements VehicleApiInterface {

    /**
     */
    async deleteVehicleRaw(requestParameters: DeleteVehicleRequest): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async deleteVehicle(requestParameters: DeleteVehicleRequest): Promise<Vehicle> {
        const response = await this.deleteVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTotalVehicleCountRaw(requestParameters: GetTotalVehicleCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/totalVehicleCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getTotalVehicleCount(requestParameters: GetTotalVehicleCountRequest): Promise<number> {
        const response = await this.getTotalVehicleCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleRaw(requestParameters: GetVehicleRequest): Promise<runtime.ApiResponse<VehicleDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDTOFromJSON(jsonValue));
    }

    /**
     */
    async getVehicle(requestParameters: GetVehicleRequest): Promise<VehicleDTO> {
        const response = await this.getVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleCountRaw(requestParameters: GetVehicleCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/VehicleCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getVehicleCount(requestParameters: GetVehicleCountRequest): Promise<number> {
        const response = await this.getVehicleCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleLatestTelemetryRaw(requestParameters: GetVehicleLatestTelemetryRequest): Promise<runtime.ApiResponse<VehicleTelemetryDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicleLatestTelemetry.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeKey !== undefined) {
            queryParameters['typeKey'] = requestParameters.typeKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}/latestTelemetry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTelemetryDTOFromJSON(jsonValue));
    }

    /**
     */
    async getVehicleLatestTelemetry(requestParameters: GetVehicleLatestTelemetryRequest): Promise<VehicleTelemetryDTO> {
        const response = await this.getVehicleLatestTelemetryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleStateRaw(requestParameters: GetVehicleStateRequest): Promise<runtime.ApiResponse<DeviceStateDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicleState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}/VehicleState`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceStateDTOFromJSON(jsonValue));
    }

    /**
     */
    async getVehicleState(requestParameters: GetVehicleStateRequest): Promise<DeviceStateDTO> {
        const response = await this.getVehicleStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleSuggestionsRaw(requestParameters: GetVehicleSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     */
    async getVehicleSuggestions(requestParameters: GetVehicleSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getVehicleSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleTelemetryRaw(requestParameters: GetVehicleTelemetryRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicleTelemetry.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.typeKeys) {
            queryParameters['typeKeys'] = requestParameters.typeKeys;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}/telemetry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesDataDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getVehicleTelemetry(requestParameters: GetVehicleTelemetryRequest): Promise<TimeSeriesDataDTOListPagedResponse> {
        const response = await this.getVehicleTelemetryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehicleTelemetrySdsRaw(requestParameters: GetVehicleTelemetrySdsRequest): Promise<runtime.ApiResponse<VehicleTelemetrySdsDTOListPagedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicleTelemetrySds.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}/telemetry/sds`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTelemetrySdsDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getVehicleTelemetrySds(requestParameters: GetVehicleTelemetrySdsRequest): Promise<VehicleTelemetrySdsDTOListPagedResponse> {
        const response = await this.getVehicleTelemetrySdsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getVehiclesRaw(requestParameters: GetVehiclesRequest): Promise<runtime.ApiResponse<Array<VehicleDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleDTOFromJSON));
    }

    /**
     */
    async getVehicles(requestParameters: GetVehiclesRequest): Promise<Array<VehicleDTO>> {
        const response = await this.getVehiclesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postCommandToVehicleRaw(requestParameters: PostCommandToVehicleRequest): Promise<runtime.ApiResponse<CommandLogDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postCommandToVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}/command`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommandPostDTOToJSON(requestParameters.commandPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandLogDTOFromJSON(jsonValue));
    }

    /**
     */
    async postCommandToVehicle(requestParameters: PostCommandToVehicleRequest): Promise<CommandLogDTO> {
        const response = await this.postCommandToVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postVehicleRaw(requestParameters: PostVehicleRequest): Promise<runtime.ApiResponse<VehicleDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehiclePostDTOToJSON(requestParameters.vehiclePostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDTOFromJSON(jsonValue));
    }

    /**
     */
    async postVehicle(requestParameters: PostVehicleRequest): Promise<VehicleDTO> {
        const response = await this.postVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async putVehicleRaw(requestParameters: PutVehicleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Vehicle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleUpdateDTOToJSON(requestParameters.vehicleUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putVehicle(requestParameters: PutVehicleRequest): Promise<void> {
        await this.putVehicleRaw(requestParameters);
    }

}
