const dev = {
    root: '/',
    base_url: 'localhost:3000',
    //api_url: "https://api.cekip.lemonsoft.com.tr",
    signalr: "https://signalr.iot.lemonsoft.com.tr",
    api_url: "https://localhost:7001",
    //signalr: "https://localhost:7001",

}
const _config = process.env.NODE_ENV === 'production' ?
    (

        {
            ...dev,
            base_url: 'https://cekip.lemonsoft.com.tr',
            api_url: "https://api.cekip.lemonsoft.com.tr",
            signalr: "https://api.cekip.lemonsoft.com.tr",

        }
    )
    :
    dev

export default {
    tokenKey: "uafsasfjwo",
    ..._config
}

