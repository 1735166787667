/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DeviceModelUpdateDTO
 */
export interface DeviceModelUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceModelUpdateDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelUpdateDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelUpdateDTO
     */
    driverClassName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelUpdateDTO
     */
    id?: string;

}

export function DeviceModelUpdateDTOFromJSON(json: any): DeviceModelUpdateDTO {
    return DeviceModelUpdateDTOFromJSONTyped(json, false);
}

export function DeviceModelUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModelUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'driverClassName': !exists(json, 'driverClassName') ? undefined : json['driverClassName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DeviceModelUpdateDTOToJSON(value?: DeviceModelUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'driverClassName': value.driverClassName,
        'id': value.id,
    };
}


