/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface RouteResponseDTO
 */
export interface RouteResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    anxietyScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    heartAttackScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    overallScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    trafficScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    noiseScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    respiratoryScore?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    duration?: number;

    /**
     * 
     * @type {number}
     * @memberof RouteResponseDTO
     */
    distance?: number;

    /**
     * 
     * @type {string}
     * @memberof RouteResponseDTO
     */
    color?: string | null;

    /**
     * 
     * @type {Array<number>}
     * @memberof RouteResponseDTO
     */
    bbox?: Array<number> | null;

    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RouteResponseDTO
     */
    coordinates?: Array<Array<number>> | null;

}

export function RouteResponseDTOFromJSON(json: any): RouteResponseDTO {
    return RouteResponseDTOFromJSONTyped(json, false);
}

export function RouteResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anxietyScore': !exists(json, 'anxietyScore') ? undefined : json['anxietyScore'],
        'heartAttackScore': !exists(json, 'heartAttackScore') ? undefined : json['heartAttackScore'],
        'overallScore': !exists(json, 'overallScore') ? undefined : json['overallScore'],
        'trafficScore': !exists(json, 'trafficScore') ? undefined : json['trafficScore'],
        'noiseScore': !exists(json, 'noiseScore') ? undefined : json['noiseScore'],
        'respiratoryScore': !exists(json, 'respiratoryScore') ? undefined : json['respiratoryScore'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'bbox': !exists(json, 'bbox') ? undefined : json['bbox'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
    };
}

export function RouteResponseDTOToJSON(value?: RouteResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anxietyScore': value.anxietyScore,
        'heartAttackScore': value.heartAttackScore,
        'overallScore': value.overallScore,
        'trafficScore': value.trafficScore,
        'noiseScore': value.noiseScore,
        'respiratoryScore': value.respiratoryScore,
        'duration': value.duration,
        'distance': value.distance,
        'color': value.color,
        'bbox': value.bbox,
        'coordinates': value.coordinates,
    };
}


