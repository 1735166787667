import { observable, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { RoleDTO, RolePostDTO, RoleUpdateDTO } from '../../api';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useStore } from '../../StoreProvider';

export const useRoleList = (options?: (UseQueryOptions<RoleDTO[]>)) => {
    const store = useStore()

    return useQuery<RoleDTO[]>(['roles'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.userApi.getRoles({})
    }, options)
}

export const useAddRoleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: RolePostDTO) => store.api.userApi.postRole({ rolePostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['roles'])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['roles'])

            // Optimistically update to the new value
            queryClient.setQueryData<RoleDTO[]>(['roles'], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['roles'], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['roles'])
        },
    })
}

export const useUpdateRoleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation<void, any, RoleUpdateDTO, any>((d: RoleUpdateDTO) => store.api.userApi.putRole({ id: d.id!, roleUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['role', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['role', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<RoleDTO>(['role', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['role', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['roles'])
        },
    })
}

export const useDeleteRoleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: string) => store.api.userApi.deleteRole({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(["roles"])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(["roles"])

            // Optimistically update to the new value
            queryClient.setQueryData<RoleDTO[]>(["roles"], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(["roles"], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(["roles"])
        },
    })
}
class RoleStore {
    rootStore: RootStore
    @observable isLoading = true

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }
}

export default RoleStore