import { ThemeOptions } from "@material-ui/core"

const baseTheme: ThemeOptions = {
    overrides: {
        MuiButton: {
            root: {
                borderRadius: "16px"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "16px"
            }
        }
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif', 'Helvetica Neue', 'Arial'].join(','),
    },
}

export default baseTheme