import moment from "moment"
// @ts-ignore
import tr from 'moment/locale/tr'
//@ts-ignore
import en from 'moment/locale/en-gb'
import i18n from '../i18n'

moment.updateLocale('tr', tr)
moment.updateLocale('en', en)

export function updateLocale() {

    if (i18n.language === 'en') {
        moment.locale('en')
    } else {
        moment.locale('tr')
    }
}

export function formatDate(date: Date) {
    const now = moment()
    const start = moment(date)
    const diff = now.isSame(start, 'day')
    if (diff) {
        return start.format('H:mm')
    } if (start.year() === now.year()) {
        return start.format('D MMM')
    }
    return start.format('D.MM.Y')
}

export function formatDuration(diff: any) {
    return moment.utc(diff).format("HH:mm:ss")
}


export default moment
