/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    ActionType,
    ActionTypeFromJSON,
    ActionTypeFromJSONTyped,
    ActionTypeToJSON,
} from './';

/**
 * Iot.Data.Models.RuleEngineAlarm sonucunda uygulanan aksiyon
 * @export
 * @interface RuleEngineAlarmAction
 */
export interface RuleEngineAlarmAction {
    /**
     * 
     * @type {any}
     * @memberof RuleEngineAlarmAction
     */
    options?: any | null;

    /**
     * 
     * @type {ActionType}
     * @memberof RuleEngineAlarmAction
     */
    actionType?: ActionType;

}

export function RuleEngineAlarmActionFromJSON(json: any): RuleEngineAlarmAction {
    return RuleEngineAlarmActionFromJSONTyped(json, false);
}

export function RuleEngineAlarmActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': !exists(json, 'options') ? undefined : json['options'],
        'actionType': !exists(json, 'actionType') ? undefined : ActionTypeFromJSON(json['actionType']),
    };
}

export function RuleEngineAlarmActionToJSON(value?: RuleEngineAlarmAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': value.options,
        'actionType': ActionTypeToJSON(value.actionType),
    };
}


