/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface TimeSeriesDataDTO
 */
export interface TimeSeriesDataDTO {
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataDTO
     */
    typeKey?: string | null;

    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesDataDTO
     */
    timestamp?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesDataDTO
     */
    transportTimestamp?: moment.Moment;

    /**
     * 
     * @type {boolean}
     * @memberof TimeSeriesDataDTO
     */
    bool?: boolean | null;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataDTO
     */
    str?: string | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTO
     */
    _long?: number | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTO
     */
    _float?: number | null;

    /**
     * 
     * @type {any}
     * @memberof TimeSeriesDataDTO
     */
    json?: any | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTO
     */
    lat?: number | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTO
     */
    lon?: number | null;

}

export function TimeSeriesDataDTOFromJSON(json: any): TimeSeriesDataDTO {
    return TimeSeriesDataDTOFromJSONTyped(json, false);
}

export function TimeSeriesDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (moment(json['timestamp'])),

        'transportTimestamp': !exists(json, 'transportTimestamp') ? undefined : (moment(json['transportTimestamp'])),

        'bool': !exists(json, 'bool') ? undefined : json['bool'],
        'str': !exists(json, 'str') ? undefined : json['str'],
        '_long': !exists(json, 'long') ? undefined : json['long'],
        '_float': !exists(json, 'float') ? undefined : json['float'],
        'json': !exists(json, 'json') ? undefined : json['json'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
    };
}

export function TimeSeriesDataDTOToJSON(value?: TimeSeriesDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'typeKey': value.typeKey,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'transportTimestamp': value.transportTimestamp === undefined ? undefined : (value.transportTimestamp.toISOString()),
        'bool': value.bool,
        'str': value.str,
        'long': value._long,
        'float': value._float,
        'json': value.json,
        'lat': value.lat,
        'lon': value.lon,
    };
}


