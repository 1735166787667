/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface SensorDataDTOCalib
 */
export interface SensorDataDTOCalib {
    /**
     * 
     * @type {string}
     * @memberof SensorDataDTOCalib
     */
    deviceId?: string;

    /**
     * 
     * @type {Date}
     * @memberof SensorDataDTOCalib
     */
    bucketStart?: moment.Moment;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    airTempRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    airHumRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    pm2p5Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    co2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    pm10Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    h2SRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    sO2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    nH3Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    nO2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    coRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    nO2Mics4514Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    coMics4514Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    o3Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly airTempCalib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly airHumCalib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly pm2p5Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly co2Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly pm10Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly h2SCalib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly sO2Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly nH3Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly nO2Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly coCalib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly nO2Mics4514Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly coMics4514Calib?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTOCalib
     */
    readonly o3Calib?: number | null;

}

export function SensorDataDTOCalibFromJSON(json: any): SensorDataDTOCalib {
    return SensorDataDTOCalibFromJSONTyped(json, false);
}

export function SensorDataDTOCalibFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorDataDTOCalib {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'bucketStart': !exists(json, 'bucketStart') ? undefined : (moment(json['bucketStart'])),

        'airTempRaw': !exists(json, 'airTempRaw') ? undefined : json['airTempRaw'],
        'airHumRaw': !exists(json, 'airHumRaw') ? undefined : json['airHumRaw'],
        'pm2p5Raw': !exists(json, 'pm2p5Raw') ? undefined : json['pm2p5Raw'],
        'co2Raw': !exists(json, 'co2Raw') ? undefined : json['co2Raw'],
        'pm10Raw': !exists(json, 'pm10Raw') ? undefined : json['pm10Raw'],
        'h2SRaw': !exists(json, 'h2SRaw') ? undefined : json['h2SRaw'],
        'sO2Raw': !exists(json, 'sO2Raw') ? undefined : json['sO2Raw'],
        'nH3Raw': !exists(json, 'nH3Raw') ? undefined : json['nH3Raw'],
        'nO2Raw': !exists(json, 'nO2Raw') ? undefined : json['nO2Raw'],
        'coRaw': !exists(json, 'coRaw') ? undefined : json['coRaw'],
        'nO2Mics4514Raw': !exists(json, 'nO2Mics4514Raw') ? undefined : json['nO2Mics4514Raw'],
        'coMics4514Raw': !exists(json, 'coMics4514Raw') ? undefined : json['coMics4514Raw'],
        'o3Raw': !exists(json, 'o3Raw') ? undefined : json['o3Raw'],
        'airTempCalib': !exists(json, 'airTempCalib') ? undefined : json['airTempCalib'],
        'airHumCalib': !exists(json, 'airHumCalib') ? undefined : json['airHumCalib'],
        'pm2p5Calib': !exists(json, 'pm2p5Calib') ? undefined : json['pm2p5Calib'],
        'co2Calib': !exists(json, 'co2Calib') ? undefined : json['co2Calib'],
        'pm10Calib': !exists(json, 'pm10Calib') ? undefined : json['pm10Calib'],
        'h2SCalib': !exists(json, 'h2SCalib') ? undefined : json['h2SCalib'],
        'sO2Calib': !exists(json, 'sO2Calib') ? undefined : json['sO2Calib'],
        'nH3Calib': !exists(json, 'nH3Calib') ? undefined : json['nH3Calib'],
        'nO2Calib': !exists(json, 'nO2Calib') ? undefined : json['nO2Calib'],
        'coCalib': !exists(json, 'coCalib') ? undefined : json['coCalib'],
        'nO2Mics4514Calib': !exists(json, 'nO2Mics4514Calib') ? undefined : json['nO2Mics4514Calib'],
        'coMics4514Calib': !exists(json, 'coMics4514Calib') ? undefined : json['coMics4514Calib'],
        'o3Calib': !exists(json, 'o3Calib') ? undefined : json['o3Calib'],
    };
}

export function SensorDataDTOCalibToJSON(value?: SensorDataDTOCalib | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'bucketStart': value.bucketStart === undefined ? undefined : (value.bucketStart.toISOString()),
        'airTempRaw': value.airTempRaw,
        'airHumRaw': value.airHumRaw,
        'pm2p5Raw': value.pm2p5Raw,
        'co2Raw': value.co2Raw,
        'pm10Raw': value.pm10Raw,
        'h2SRaw': value.h2SRaw,
        'sO2Raw': value.sO2Raw,
        'nH3Raw': value.nH3Raw,
        'nO2Raw': value.nO2Raw,
        'coRaw': value.coRaw,
        'nO2Mics4514Raw': value.nO2Mics4514Raw,
        'coMics4514Raw': value.coMics4514Raw,
        'o3Raw': value.o3Raw,
    };
}


