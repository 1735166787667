import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configure } from "mobx"; configure({ enforceActions: "never" });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.register({
//     onUpdate: (registration) => {
//         if (registration.waiting) {
//             store.notification.enqueue({
//                 message: i18n.t("common.errors.newVersion"),
//                 options: {
//                     variant: 'info',
//                     persist: true,
//                     preventDuplicate: true,
//                     action: (<Button size="small" onClick={() => {

//                         registration.waiting!.postMessage({ type: 'SKIP_WAITING' })
//                         window.location.reload()

//                     }} >
//                         {i18n.t("common.updateNow")}
//                     </Button>)
//                 }
//             })
//         }
//     }
// });


serviceWorker.unregister()