/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface TenantDTO
 */
export interface TenantDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    shortName?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    disabled?: boolean;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    representative?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    contact?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    address?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    id?: string;

    /**
     * 
     * @type {Date}
     * @memberof TenantDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof TenantDTO
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    mainTenant?: boolean;

    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    logo?: string | null;

}

export function TenantDTOFromJSON(json: any): TenantDTO {
    return TenantDTOFromJSONTyped(json, false);
}

export function TenantDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'representative': !exists(json, 'representative') ? undefined : json['representative'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'mainTenant': !exists(json, 'mainTenant') ? undefined : json['mainTenant'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
    };
}

export function TenantDTOToJSON(value?: TenantDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortName': value.shortName,
        'disabled': value.disabled,
        'representative': value.representative,
        'contact': value.contact,
        'address': value.address,
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'mainTenant': value.mainTenant,
        'logo': value.logo,
    };
}


