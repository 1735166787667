import { ChangeEvent } from "react";
import DataStore from "./DataStore";
import RootStore from "./RootStore"
class UserStore {
    rootStore: RootStore
    ImpersonationTenantId: string | null = null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    handleImpersonationChange = (event: ChangeEvent<{ value: any }>) => {
        this.ImpersonationTenantId = event.target.value;
        this.rootStore.dataStore = new DataStore(this.rootStore)
        this.rootStore.routing.goTo("force", { return: this.rootStore.routing.getCurrentRoute()!.name });
    };
}

export default UserStore