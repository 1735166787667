/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface VehicleTelemetrySdsDTO
 */
export interface VehicleTelemetrySdsDTO {
    /**
     * 
     * @type {string}
     * @memberof VehicleTelemetrySdsDTO
     */
    vehicleId?: string;

    /**
     * 
     * @type {boolean}
     * @memberof VehicleTelemetrySdsDTO
     */
    ignition?: boolean;

    /**
     * 
     * @type {number}
     * @memberof VehicleTelemetrySdsDTO
     */
    odometer?: number | null;

    /**
     * 
     * @type {Date}
     * @memberof VehicleTelemetrySdsDTO
     */
    timestamp?: moment.Moment;

}

export function VehicleTelemetrySdsDTOFromJSON(json: any): VehicleTelemetrySdsDTO {
    return VehicleTelemetrySdsDTOFromJSONTyped(json, false);
}

export function VehicleTelemetrySdsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleTelemetrySdsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'ignition': !exists(json, 'ignition') ? undefined : json['ignition'],
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (moment(json['timestamp'])),

    };
}

export function VehicleTelemetrySdsDTOToJSON(value?: VehicleTelemetrySdsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleId': value.vehicleId,
        'ignition': value.ignition,
        'odometer': value.odometer,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
    };
}


