/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    TenantDTO,
    TenantDTOFromJSON,
    TenantDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetTenantRequest {
    id: string;
    xTenantId?: string;
}

export interface GetTenantsRequest {
    xTenantId?: string;
}

export interface GetUserOwnTenantRequest {
    xTenantId?: string;
}

/**
 * TenantApi - interface
 * 
 * @export
 * @interface TenantApiInterface
 */
export interface TenantApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantRaw(requestParameters: GetTenantRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     */
    getTenant(requestParameters: GetTenantRequest): Promise<TenantDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantsRaw(requestParameters: GetTenantsRequest): Promise<runtime.ApiResponse<Array<TenantDTO>>>;

    /**
     */
    getTenants(requestParameters: GetTenantsRequest): Promise<Array<TenantDTO>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getUserOwnTenantRaw(requestParameters: GetUserOwnTenantRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     */
    getUserOwnTenant(requestParameters: GetUserOwnTenantRequest): Promise<TenantDTO>;

}

/**
 * 
 */
export class TenantApi extends runtime.BaseAPI implements TenantApiInterface {

    /**
     */
    async getTenantRaw(requestParameters: GetTenantRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     */
    async getTenant(requestParameters: GetTenantRequest): Promise<TenantDTO> {
        const response = await this.getTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTenantsRaw(requestParameters: GetTenantsRequest): Promise<runtime.ApiResponse<Array<TenantDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantDTOFromJSON));
    }

    /**
     */
    async getTenants(requestParameters: GetTenantsRequest): Promise<Array<TenantDTO>> {
        const response = await this.getTenantsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserOwnTenantRaw(requestParameters: GetUserOwnTenantRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Tenant/own`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUserOwnTenant(requestParameters: GetUserOwnTenantRequest): Promise<TenantDTO> {
        const response = await this.getUserOwnTenantRaw(requestParameters);
        return await response.value();
    }

}
