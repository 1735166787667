/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    RuleEngineAlarm,
    RuleEngineAlarmFromJSON,
    RuleEngineAlarmFromJSONTyped,
    RuleEngineAlarmToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * Cihaz gruplarÄ±
 * @export
 * @interface DeviceGroup
 */
export interface DeviceGroup {
    /**
     * 
     * @type {Date}
     * @memberof DeviceGroup
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceGroup
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof DeviceGroup
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    id?: string;

    /**
     * 
     * @type {Tenant}
     * @memberof DeviceGroup
     */
    tenant?: Tenant;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    description?: string | null;

    /**
     * Gruba tanÄ±mlanmÄ±ÅŸ alarmlarÄ±n listesi
     * @type {Array<RuleEngineAlarm>}
     * @memberof DeviceGroup
     */
    ruleEngineAlarms?: Array<RuleEngineAlarm> | null;

    /**
     * Ãœst grup Id
     * @type {string}
     * @memberof DeviceGroup
     */
    parentId?: string | null;

    /**
     * 
     * @type {DeviceGroup}
     * @memberof DeviceGroup
     */
    parent?: DeviceGroup;

    /**
     * MÃ¼ÅŸteri tarafÄ±ndan eklenebilecek ek bilgiler
     * @type {any}
     * @memberof DeviceGroup
     */
    customAttributes?: any | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    tenantId?: string;

}

export function DeviceGroupFromJSON(json: any): DeviceGroup {
    return DeviceGroupFromJSONTyped(json, false);
}

export function DeviceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ruleEngineAlarms': !exists(json, 'ruleEngineAlarms') ? undefined : (json['ruleEngineAlarms'] === null ? null : (json['ruleEngineAlarms'] as Array<any>).map(RuleEngineAlarmFromJSON)),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'parent': !exists(json, 'parent') ? undefined : DeviceGroupFromJSON(json['parent']),
        'customAttributes': !exists(json, 'customAttributes') ? undefined : json['customAttributes'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function DeviceGroupToJSON(value?: DeviceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'tenant': TenantToJSON(value.tenant),
        'name': value.name,
        'description': value.description,
        'ruleEngineAlarms': value.ruleEngineAlarms === undefined ? undefined : (value.ruleEngineAlarms === null ? null : (value.ruleEngineAlarms as Array<any>).map(RuleEngineAlarmToJSON)),
        'parentId': value.parentId,
        'parent': DeviceGroupToJSON(value.parent),
        'customAttributes': value.customAttributes,
        'tenantId': value.tenantId,
    };
}


