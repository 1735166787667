/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    TimeSeriesDataDTO,
    TimeSeriesDataDTOFromJSON,
    TimeSeriesDataDTOFromJSONTyped,
    TimeSeriesDataDTOToJSON,
} from './';

/**
 * Pagination yardÄ±mcÄ± classÄ±
 * @export
 * @interface TimeSeriesDataDTOListPagedResponse
 */
export interface TimeSeriesDataDTOListPagedResponse {
    /**
     * 
     * @type {Array<TimeSeriesDataDTO>}
     * @memberof TimeSeriesDataDTOListPagedResponse
     */
    data?: Array<TimeSeriesDataDTO> | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTOListPagedResponse
     */
    pageNumber?: number;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTOListPagedResponse
     */
    pageSize?: number;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTOListPagedResponse
     */
    totalPages?: number;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataDTOListPagedResponse
     */
    totalRecords?: number;

}

export function TimeSeriesDataDTOListPagedResponseFromJSON(json: any): TimeSeriesDataDTOListPagedResponse {
    return TimeSeriesDataDTOListPagedResponseFromJSONTyped(json, false);
}

export function TimeSeriesDataDTOListPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesDataDTOListPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(TimeSeriesDataDTOFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
    };
}

export function TimeSeriesDataDTOListPagedResponseToJSON(value?: TimeSeriesDataDTOListPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(TimeSeriesDataDTOToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'totalRecords': value.totalRecords,
    };
}


