/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    ConnectionProtocols,
    ConnectionProtocolsFromJSON,
    ConnectionProtocolsFromJSONTyped,
    ConnectionProtocolsToJSON,
} from './';

/**
 * CihazlarÄ±n mesaj protokollerine uygun olarak gelen ham mesajlarÄ± standart IOT platform mesajÄ±na ve giden mesajlarÄ±
 *             <br />CihazÄ±n ham mesaj protokollerine Ã§eviren yapÄ±lar
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * SÃ¼rÃ¼cÃ¼ implemantasyonundaki class adÄ±
     * @type {string}
     * @memberof Driver
     */
    className?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    description?: string | null;

    /**
     * 
     * @type {Array<ConnectionProtocols>}
     * @memberof Driver
     */
    supportedProtocols?: Array<ConnectionProtocols> | null;

    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    identifierRegex?: string | null;

    /**
     * TCP ve UDP protocoller iÃ§in dinleme yapÄ±lacak port
     * @type {number}
     * @memberof Driver
     */
    port?: number | null;

    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    isBinary?: boolean | null;

}

export function DriverFromJSON(json: any): Driver {
    return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'className': !exists(json, 'className') ? undefined : json['className'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'supportedProtocols': !exists(json, 'supportedProtocols') ? undefined : (json['supportedProtocols'] === null ? null : (json['supportedProtocols'] as Array<any>).map(ConnectionProtocolsFromJSON)),
        'identifierRegex': !exists(json, 'identifierRegex') ? undefined : json['identifierRegex'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'isBinary': !exists(json, 'isBinary') ? undefined : json['isBinary'],
    };
}

export function DriverToJSON(value?: Driver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'className': value.className,
        'description': value.description,
        'supportedProtocols': value.supportedProtocols === undefined ? undefined : (value.supportedProtocols === null ? null : (value.supportedProtocols as Array<any>).map(ConnectionProtocolsToJSON)),
        'identifierRegex': value.identifierRegex,
        'port': value.port,
        'isBinary': value.isBinary,
    };
}


