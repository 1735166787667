/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TelemetryType {
    Bool = 'bool',
    String = 'string',
    Json = 'json',
    Long = 'long',
    Float = 'float',
    Point = 'point'
}

export function TelemetryTypeFromJSON(json: any): TelemetryType {
    return TelemetryTypeFromJSONTyped(json, false);
}

export function TelemetryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryType {
    return json as TelemetryType;
}

export function TelemetryTypeToJSON(value?: TelemetryType | null): any {
    return value as any;
}

