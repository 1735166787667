
import { createRouterState, RouterState, RouterStore } from 'mobx-state-router'
import routes from '../routes'
import ApiStore from './ApiStore';
import UiStore from './UiStore';
import NotificationStore from './NotificationStore';
import UserStore from './UserStore';
import * as Sentry from '@sentry/browser'
import DataStore from './DataStore';
import AuthStore from './AuthStore';
import SignalRStore from './SignalRStore';


const notFound = createRouterState('404')

class RootStore {
    routing: RouterStore
    authStore: AuthStore
    api: ApiStore
    uiStore: UiStore
    signalR: SignalRStore

    dataStore: DataStore
    notification: NotificationStore
    user: UserStore


    constructor() {
        this.routing = new RouterStore(routes, notFound, { rootStore: this })

        this.notification = new NotificationStore(this)
        this.user = new UserStore(this)

        this.api = new ApiStore(this)
        this.signalR = new SignalRStore(this)

        this.authStore = new AuthStore(this)

        this.uiStore = new UiStore(this)
        this.dataStore = new DataStore(this)
        /*
        this.routing.setErrorHook((error) => {
            console.log(error) // eslint-disable-line no-console
            Sentry.captureException(error, {
                tags: {
                    section: "router-error",
                },
            })
        })
        */

    }

}


export default RootStore
