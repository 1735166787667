import darkTheme from "./dark";
import lightTheme from "./light";
import greenTheme from "./green";
import green2Theme from "./green2";

const themes = {
    dark: darkTheme,
    light: lightTheme,
    green: greenTheme,
    green2: green2Theme
}

export default themes;