import RootStore from "./stores/RootStore"
import { createRouterState, Route } from 'mobx-state-router'
import config from "./config"


// const checkData = async (dataStore, rootStore: RootStore) => {
//     if (rootStore.dataStore[dataStore] && rootStore.dataStore[dataStore].list.length === 0) {
//         await rootStore.dataStore[dataStore].getList()
//     }
// }

// const updateDf = async (dataStore, rootStore: RootStore, queryParams) => {
//     if (rootStore.dataStore[dataStore]) {
//         rootStore.dataStore[dataStore].dateFilter.updateFromJson(queryParams)
//     }
// }

const globalBeforeEnter = async (fromState, toState, rootStore: RootStore) => {
    try {
        if (!rootStore.authStore.auth) {
            //routerStore.rootStore.setRedirect(toState)
            return createRouterState('login')
        }
    }
    catch (error) {
        console.log("error From route", error)
        return createRouterState('login')
    }
}


export default [
    {
        name: 'force',
        pattern: '/force',

    },
    {
        name: "404",
        pattern: "404"
    },
    {
        name: 'externallogin',
        pattern: '/externalLogin',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {

            const { rootStore } = routerStore.options
            var bool = await rootStore!.authStore.login({ userName: "test", password: "123456" });
            if (bool === true) {
                return createRouterState('dashboard')
            } else {

                return createRouterState('dashboard')
            }
        }
    },
    {
        name: 'relogin',
        pattern: '/relogin',
    },
    {
        name: 'login',
        pattern: '/',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {

            const { rootStore } = routerStore.options
            const oldToken = localStorage.getItem(config.tokenKey)

            if (oldToken) {
                rootStore.authStore.auth = true
                return createRouterState('dashboard')

            }
        },
        onEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const {
                rootStore
            } = routerStore.options
            if (rootStore.authStore.auth) {
                /*
                if (rootStore.authStore.redirect) {
                    return Promise.reject(rootStore.authStore.redirect)
                }
                */
                return createRouterState('dashboard')
            }
        }
    },

    {
        name: 'dashboard',
        pattern: '/dashboard',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'sensorvalues',
        pattern: '/sensorvalues',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },

    {
        name: 'definitions',
        pattern: '/definitions',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.tenant',
        pattern: '/definitions/tenant',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.sensor',
        pattern: '/definitions/sensor',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.device',
        pattern: '/definitions/device',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.vehicle',
        pattern: '/definitions/vehicle',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.ruleChain',
        pattern: '/definitions/ruleChain',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'ruleEngine.ruleEditor',
        pattern: '/ruleEngine/ruleEditor/:id',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.deviceModel',
        pattern: '/definitions/deviceModel',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.deviceGroup',
        pattern: '/definitions/deviceGroup',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.user',
        pattern: '/definitions/user',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.role',
        pattern: '/definitions/role',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.alarm',
        pattern: '/definitions/alarm',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'devicemap',
        pattern: '/devicemap',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'mapview',
        pattern: '/mapview',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'route.green',
        pattern: '/route/green',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'route.health',
        pattern: '/route/health',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'information.health',
        pattern: '/information/health',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options


        }
    },
    {
        name: 'information.about',
        pattern: '/information/about',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options


        }
    },
    {
        name: 'information.sensors',
        pattern: '/information/sensors',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options


        }
    },
    {
        name: 'rawData',
        pattern: '/rawData',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'rawDataTable',
        pattern: '/rawData/table',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'rawDataTableCalib',
        pattern: '/rawData/table/calib',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },

] as Route[]