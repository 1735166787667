/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleEngineAlarmAction,
    RuleEngineAlarmActionFromJSON,
    RuleEngineAlarmActionFromJSONTyped,
    RuleEngineAlarmActionToJSON,
    RuleEngineAlarmCondition,
    RuleEngineAlarmConditionFromJSON,
    RuleEngineAlarmConditionFromJSONTyped,
    RuleEngineAlarmConditionToJSON,
} from './';

/**
 * Iot.Data.Models.RuleEngineAlarm iÃ§in tanÄ±mlanan kurallar ve aksiyonlar.
 * @export
 * @interface RuleEngineAlarmConfiguration
 */
export interface RuleEngineAlarmConfiguration {
    /**
     * Kurallar
     * @type {Array<RuleEngineAlarmCondition>}
     * @memberof RuleEngineAlarmConfiguration
     */
    conditions?: Array<RuleEngineAlarmCondition> | null;

    /**
     * Aksiyonlar
     * @type {Array<RuleEngineAlarmAction>}
     * @memberof RuleEngineAlarmConfiguration
     */
    actions?: Array<RuleEngineAlarmAction> | null;

}

export function RuleEngineAlarmConfigurationFromJSON(json: any): RuleEngineAlarmConfiguration {
    return RuleEngineAlarmConfigurationFromJSONTyped(json, false);
}

export function RuleEngineAlarmConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'] === null ? null : (json['conditions'] as Array<any>).map(RuleEngineAlarmConditionFromJSON)),
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] === null ? null : (json['actions'] as Array<any>).map(RuleEngineAlarmActionFromJSON)),
    };
}

export function RuleEngineAlarmConfigurationToJSON(value?: RuleEngineAlarmConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': value.conditions === undefined ? undefined : (value.conditions === null ? null : (value.conditions as Array<any>).map(RuleEngineAlarmConditionToJSON)),
        'actions': value.actions === undefined ? undefined : (value.actions === null ? null : (value.actions as Array<any>).map(RuleEngineAlarmActionToJSON)),
    };
}


