/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleChain,
    RuleChainFromJSON,
    RuleChainFromJSONTyped,
    RuleChainToJSON,
    RuleNode,
    RuleNodeFromJSON,
    RuleNodeFromJSONTyped,
    RuleNodeToJSON,
} from './';

/**
 * Iot.Data.Models.RuleChain iÃ§erisindeki Iot.Data.Models.RuleNode iliÅŸkileri
 * @export
 * @interface RuleNodeRelation
 */
export interface RuleNodeRelation {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelation
     */
    ruleChainId?: string;

    /**
     * 
     * @type {RuleChain}
     * @memberof RuleNodeRelation
     */
    ruleChain?: RuleChain;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelation
     */
    fromId?: string;

    /**
     * 
     * @type {RuleNode}
     * @memberof RuleNodeRelation
     */
    from?: RuleNode;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelation
     */
    toId?: string;

    /**
     * 
     * @type {RuleNode}
     * @memberof RuleNodeRelation
     */
    to?: RuleNode;

    /**
     * Ä°liÅŸki tipi, Ã§ok Ã§Ä±kÄ±ÅŸlÄ± nodelarda Ã§Ä±kÄ±ÅŸlarÄ± ayÄ±rt etmek iÃ§in.
     * @type {string}
     * @memberof RuleNodeRelation
     */
    type?: string | null;

}

export function RuleNodeRelationFromJSON(json: any): RuleNodeRelation {
    return RuleNodeRelationFromJSONTyped(json, false);
}

export function RuleNodeRelationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNodeRelation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        'ruleChain': !exists(json, 'ruleChain') ? undefined : RuleChainFromJSON(json['ruleChain']),
        'fromId': !exists(json, 'fromId') ? undefined : json['fromId'],
        'from': !exists(json, 'from') ? undefined : RuleNodeFromJSON(json['from']),
        'toId': !exists(json, 'toId') ? undefined : json['toId'],
        'to': !exists(json, 'to') ? undefined : RuleNodeFromJSON(json['to']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeRelationToJSON(value?: RuleNodeRelation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleChainId': value.ruleChainId,
        'ruleChain': RuleChainToJSON(value.ruleChain),
        'fromId': value.fromId,
        'from': RuleNodeToJSON(value.from),
        'toId': value.toId,
        'to': RuleNodeToJSON(value.to),
        'type': value.type,
    };
}


