/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    DeviceGroup,
    DeviceGroupFromJSON,
    DeviceGroupFromJSONTyped,
    DeviceGroupToJSON,
    DeviceModel,
    DeviceModelFromJSON,
    DeviceModelFromJSONTyped,
    DeviceModelToJSON,
    RuleEngineAlarmConfiguration,
    RuleEngineAlarmConfigurationFromJSON,
    RuleEngineAlarmConfigurationFromJSONTyped,
    RuleEngineAlarmConfigurationToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * Kural motoru alarmlarÄ±, Cihaz verileri Ã¼zerinde basit kurallar ile Iot.Data.Models.AlarmEvent oluÅŸturur.
 * @export
 * @interface RuleEngineAlarm
 */
export interface RuleEngineAlarm {
    /**
     * 
     * @type {Date}
     * @memberof RuleEngineAlarm
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof RuleEngineAlarm
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof RuleEngineAlarm
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    description?: string | null;

    /**
     * 
     * @type {Tenant}
     * @memberof RuleEngineAlarm
     */
    tenant?: Tenant;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    deviceId?: string | null;

    /**
     * 
     * @type {Device}
     * @memberof RuleEngineAlarm
     */
    device?: Device;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    deviceGroupId?: string | null;

    /**
     * 
     * @type {DeviceGroup}
     * @memberof RuleEngineAlarm
     */
    deviceGroup?: DeviceGroup;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    deviceModelId?: string | null;

    /**
     * 
     * @type {DeviceModel}
     * @memberof RuleEngineAlarm
     */
    deviceModel?: DeviceModel;

    /**
     * 
     * @type {RuleEngineAlarmConfiguration}
     * @memberof RuleEngineAlarm
     */
    _configuration?: RuleEngineAlarmConfiguration;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarm
     */
    tenantId?: string;

}

export function RuleEngineAlarmFromJSON(json: any): RuleEngineAlarm {
    return RuleEngineAlarmFromJSONTyped(json, false);
}

export function RuleEngineAlarmFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'device': !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
        'deviceGroup': !exists(json, 'deviceGroup') ? undefined : DeviceGroupFromJSON(json['deviceGroup']),
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'deviceModel': !exists(json, 'deviceModel') ? undefined : DeviceModelFromJSON(json['deviceModel']),
        '_configuration': !exists(json, 'configuration') ? undefined : RuleEngineAlarmConfigurationFromJSON(json['configuration']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function RuleEngineAlarmToJSON(value?: RuleEngineAlarm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'tenant': TenantToJSON(value.tenant),
        'deviceId': value.deviceId,
        'device': DeviceToJSON(value.device),
        'deviceGroupId': value.deviceGroupId,
        'deviceGroup': DeviceGroupToJSON(value.deviceGroup),
        'deviceModelId': value.deviceModelId,
        'deviceModel': DeviceModelToJSON(value.deviceModel),
        'configuration': RuleEngineAlarmConfigurationToJSON(value._configuration),
        'tenantId': value.tenantId,
    };
}


