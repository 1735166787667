/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    ClaimDTO,
    ClaimDTOFromJSON,
    ClaimDTOToJSON,
    JsonWebToken,
    JsonWebTokenFromJSON,
    JsonWebTokenToJSON,
    LoginDto,
    LoginDtoFromJSON,
    LoginDtoToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    RoleDTO,
    RoleDTOFromJSON,
    RoleDTOToJSON,
    RolePostDTO,
    RolePostDTOFromJSON,
    RolePostDTOToJSON,
    RoleUpdateDTO,
    RoleUpdateDTOFromJSON,
    RoleUpdateDTOToJSON,
    TenantDTO,
    TenantDTOFromJSON,
    TenantDTOToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserDTO,
    UserDTOFromJSON,
    UserDTOToJSON,
    UserPostDTO,
    UserPostDTOFromJSON,
    UserPostDTOToJSON,
    UserUpdateDTO,
    UserUpdateDTOFromJSON,
    UserUpdateDTOToJSON,
} from '../models';

export interface DeleteRoleRequest {
    id: string;
    xTenantId?: string;
}

export interface DeleteUserRequest {
    id: string;
    xTenantId?: string;
}

export interface GetAvaiablePermissionsRequest {
    xTenantId?: string;
}

export interface GetClaimsRequest {
    xTenantId?: string;
}

export interface GetRoleRequest {
    id: string;
    xTenantId?: string;
}

export interface GetRolesRequest {
    xTenantId?: string;
}

export interface GetUserRequest {
    id: string;
    xTenantId?: string;
}

export interface GetUserTenantRequest {
    xTenantId?: string;
}

export interface GetUsersRequest {
    roles?: Array<string>;
    xTenantId?: string;
}

export interface LoginRequest {
    xTenantId?: string;
    loginDto?: LoginDto;
}

export interface PostRoleRequest {
    xTenantId?: string;
    rolePostDTO?: RolePostDTO;
}

export interface PostUserRequest {
    xTenantId?: string;
    userPostDTO?: UserPostDTO;
}

export interface PutRoleRequest {
    id: string;
    xTenantId?: string;
    roleUpdateDTO?: RoleUpdateDTO;
}

export interface PutUserRequest {
    id: string;
    xTenantId?: string;
    userUpdateDTO?: UserUpdateDTO;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteRoleRaw(requestParameters: DeleteRoleRequest): Promise<runtime.ApiResponse<Role>>;

    /**
     */
    deleteRole(requestParameters: DeleteRoleRequest): Promise<Role>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<User>>;

    /**
     */
    deleteUser(requestParameters: DeleteUserRequest): Promise<User>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getAvaiablePermissionsRaw(requestParameters: GetAvaiablePermissionsRequest): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     */
    getAvaiablePermissions(requestParameters: GetAvaiablePermissionsRequest): Promise<Array<string>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getClaimsRaw(requestParameters: GetClaimsRequest): Promise<runtime.ApiResponse<Array<ClaimDTO>>>;

    /**
     */
    getClaims(requestParameters: GetClaimsRequest): Promise<Array<ClaimDTO>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getRoleRaw(requestParameters: GetRoleRequest): Promise<runtime.ApiResponse<RoleDTO>>;

    /**
     */
    getRole(requestParameters: GetRoleRequest): Promise<RoleDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getRolesRaw(requestParameters: GetRolesRequest): Promise<runtime.ApiResponse<Array<RoleDTO>>>;

    /**
     */
    getRoles(requestParameters: GetRolesRequest): Promise<Array<RoleDTO>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserDTO>>;

    /**
     */
    getUser(requestParameters: GetUserRequest): Promise<UserDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserTenantRaw(requestParameters: GetUserTenantRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     */
    getUserTenant(requestParameters: GetUserTenantRequest): Promise<TenantDTO>;

    /**
     * 
     * @param {Array<string>} [roles] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<Array<UserDTO>>>;

    /**
     */
    getUsers(requestParameters: GetUsersRequest): Promise<Array<UserDTO>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<JsonWebToken>>;

    /**
     */
    login(requestParameters: LoginRequest): Promise<JsonWebToken>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RolePostDTO} [rolePostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postRoleRaw(requestParameters: PostRoleRequest): Promise<runtime.ApiResponse<RoleDTO>>;

    /**
     */
    postRole(requestParameters: PostRoleRequest): Promise<RoleDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {UserPostDTO} [userPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    postUserRaw(requestParameters: PostUserRequest): Promise<runtime.ApiResponse<UserDTO>>;

    /**
     */
    postUser(requestParameters: PostUserRequest): Promise<UserDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RoleUpdateDTO} [roleUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putRoleRaw(requestParameters: PutRoleRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    putRole(requestParameters: PutRoleRequest): Promise<void>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {UserUpdateDTO} [userUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putUserRaw(requestParameters: PutUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    putUser(requestParameters: PutUserRequest): Promise<void>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest): Promise<runtime.ApiResponse<Role>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }

    /**
     */
    async deleteRole(requestParameters: DeleteRoleRequest): Promise<Role> {
        const response = await this.deleteRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<User> {
        const response = await this.deleteUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAvaiablePermissionsRaw(requestParameters: GetAvaiablePermissionsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/AvaiablePermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAvaiablePermissions(requestParameters: GetAvaiablePermissionsRequest): Promise<Array<string>> {
        const response = await this.getAvaiablePermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getClaimsRaw(requestParameters: GetClaimsRequest): Promise<runtime.ApiResponse<Array<ClaimDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Claims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaimDTOFromJSON));
    }

    /**
     */
    async getClaims(requestParameters: GetClaimsRequest): Promise<Array<ClaimDTO>> {
        const response = await this.getClaimsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRoleRaw(requestParameters: GetRoleRequest): Promise<runtime.ApiResponse<RoleDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async getRole(requestParameters: GetRoleRequest): Promise<RoleDTO> {
        const response = await this.getRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRolesRaw(requestParameters: GetRolesRequest): Promise<runtime.ApiResponse<Array<RoleDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Role`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDTOFromJSON));
    }

    /**
     */
    async getRoles(requestParameters: GetRolesRequest): Promise<Array<RoleDTO>> {
        const response = await this.getRolesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest): Promise<UserDTO> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserTenantRaw(requestParameters: GetUserTenantRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUserTenant(requestParameters: GetUserTenantRequest): Promise<TenantDTO> {
        const response = await this.getUserTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<Array<UserDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDTOFromJSON));
    }

    /**
     */
    async getUsers(requestParameters: GetUsersRequest): Promise<Array<UserDTO>> {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<JsonWebToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDtoToJSON(requestParameters.loginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => JsonWebTokenFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest): Promise<JsonWebToken> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postRoleRaw(requestParameters: PostRoleRequest): Promise<runtime.ApiResponse<RoleDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Role`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RolePostDTOToJSON(requestParameters.rolePostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async postRole(requestParameters: PostRoleRequest): Promise<RoleDTO> {
        const response = await this.postRoleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postUserRaw(requestParameters: PostUserRequest): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPostDTOToJSON(requestParameters.userPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async postUser(requestParameters: PostUserRequest): Promise<UserDTO> {
        const response = await this.postUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async putRoleRaw(requestParameters: PutRoleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/Role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleUpdateDTOToJSON(requestParameters.roleUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putRole(requestParameters: PutRoleRequest): Promise<void> {
        await this.putRoleRaw(requestParameters);
    }

    /**
     */
    async putUserRaw(requestParameters: PutUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/User/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateDTOToJSON(requestParameters.userUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putUser(requestParameters: PutUserRequest): Promise<void> {
        await this.putUserRaw(requestParameters);
    }

}
