/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Sistemde IOT platform cihazının takılı olduğu aracı tanımlar ve araçla ilgili detayları içerir.
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    updatedById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    tenantId?: string;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    plate?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    nickname?: string | null;

    /**
     * Araca tanımlı deviceId
     * @type {string}
     * @memberof Vehicle
     */
    deviceId?: string | null;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    sht40TemperatureZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    sht40TemperatureModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    sht40HumZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    sht40HumModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    scd30Zero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    scd30Modifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    specH2sZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    specH2sModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    specSo2Zero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    specSo2Modifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814CoZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814CoModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814Nh3Zero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814Nh3Modifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814NoZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics6814NoModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics4514NoZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics4514NoModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics64514CoZero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mics4514CoModifier?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mq131Zero?: number;

    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    mq131Modifier?: number;

}

export function VehicleFromJSON(json: any): Vehicle {
    return VehicleFromJSONTyped(json, false);
}

export function VehicleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vehicle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'sht40TemperatureZero': !exists(json, 'sht40TemperatureZero') ? undefined : json['sht40TemperatureZero'],
        'sht40TemperatureModifier': !exists(json, 'sht40TemperatureModifier') ? undefined : json['sht40TemperatureModifier'],
        'sht40HumZero': !exists(json, 'sht40HumZero') ? undefined : json['sht40HumZero'],
        'sht40HumModifier': !exists(json, 'sht40HumModifier') ? undefined : json['sht40HumModifier'],
        'scd30Zero': !exists(json, 'scd30Zero') ? undefined : json['scd30Zero'],
        'scd30Modifier': !exists(json, 'scd30Modifier') ? undefined : json['scd30Modifier'],
        'specH2sZero': !exists(json, 'specH2sZero') ? undefined : json['specH2sZero'],
        'specH2sModifier': !exists(json, 'specH2sModifier') ? undefined : json['specH2sModifier'],
        'specSo2Zero': !exists(json, 'specSo2Zero') ? undefined : json['specSo2Zero'],
        'specSo2Modifier': !exists(json, 'specSo2Modifier') ? undefined : json['specSo2Modifier'],
        'mics6814CoZero': !exists(json, 'mics6814CoZero') ? undefined : json['mics6814CoZero'],
        'mics6814CoModifier': !exists(json, 'mics6814CoModifier') ? undefined : json['mics6814CoModifier'],
        'mics6814Nh3Zero': !exists(json, 'mics6814Nh3Zero') ? undefined : json['mics6814Nh3Zero'],
        'mics6814Nh3Modifier': !exists(json, 'mics6814Nh3Modifier') ? undefined : json['mics6814Nh3Modifier'],
        'mics6814NoZero': !exists(json, 'mics6814NoZero') ? undefined : json['mics6814NoZero'],
        'mics6814NoModifier': !exists(json, 'mics6814NoModifier') ? undefined : json['mics6814NoModifier'],
        'mics4514NoZero': !exists(json, 'mics4514NoZero') ? undefined : json['mics4514NoZero'],
        'mics4514NoModifier': !exists(json, 'mics4514NoModifier') ? undefined : json['mics4514NoModifier'],
        'mics64514CoZero': !exists(json, 'mics64514CoZero') ? undefined : json['mics64514CoZero'],
        'mics4514CoModifier': !exists(json, 'mics4514CoModifier') ? undefined : json['mics4514CoModifier'],
        'mq131Zero': !exists(json, 'mq131Zero') ? undefined : json['mq131Zero'],
        'mq131Modifier': !exists(json, 'mq131Modifier') ? undefined : json['mq131Modifier'],
    };
}

export function VehicleToJSON(value?: Vehicle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'id': value.id,
        'tenantId': value.tenantId,
        'plate': value.plate,
        'nickname': value.nickname,
        'deviceId': value.deviceId,
        'sht40TemperatureZero': value.sht40TemperatureZero,
        'sht40TemperatureModifier': value.sht40TemperatureModifier,
        'sht40HumZero': value.sht40HumZero,
        'sht40HumModifier': value.sht40HumModifier,
        'scd30Zero': value.scd30Zero,
        'scd30Modifier': value.scd30Modifier,
        'specH2sZero': value.specH2sZero,
        'specH2sModifier': value.specH2sModifier,
        'specSo2Zero': value.specSo2Zero,
        'specSo2Modifier': value.specSo2Modifier,
        'mics6814CoZero': value.mics6814CoZero,
        'mics6814CoModifier': value.mics6814CoModifier,
        'mics6814Nh3Zero': value.mics6814Nh3Zero,
        'mics6814Nh3Modifier': value.mics6814Nh3Modifier,
        'mics6814NoZero': value.mics6814NoZero,
        'mics6814NoModifier': value.mics6814NoModifier,
        'mics4514NoZero': value.mics4514NoZero,
        'mics4514NoModifier': value.mics4514NoModifier,
        'mics64514CoZero': value.mics64514CoZero,
        'mics4514CoModifier': value.mics4514CoModifier,
        'mq131Zero': value.mq131Zero,
        'mq131Modifier': value.mq131Modifier,
    };
}


