import { observable, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { TenantDTO } from '../../api';
import { useQuery, UseQueryOptions } from 'react-query';
import { useStore } from '../../StoreProvider';

export const useTenantList = (options?: (UseQueryOptions<TenantDTO[]>)) => {
    const store = useStore()
    return useQuery<TenantDTO[]>(['tenants'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.tenantApi.getTenants(params as any)
    }, options)
}
class TenantStore {
    rootStore: RootStore
    //to fix mobs errors
    @observable empty: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }
}

export default TenantStore