import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import RSpinner from "react-spinkit"
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    spin: {
        color: theme.palette.primary.main
    }
}
))

interface Iprops {
    delay?: number,
    height?: string | number

}

const Spinner = observer<Iprops>((props) => {

    const { delay, height } = props
    const classes = useStyles()

    const [display, setDisplay] = useState(false);

    const enableMessage = () => {
        setDisplay(true);
    }

    useEffect(() => {
        const timer = setTimeout(enableMessage, delay);

        return () => {
            clearTimeout(timer)
        }
    }, []);

    return display ?
        <div style={{ height: height || "inherit" }} className={classes.root}>
            <RSpinner name="line-scale-pulse-out" className={classes.spin} fadeIn="none" />
        </div>
        : null

})

Spinner.defaultProps = {
    delay: 500,
} as Partial<Iprops>;

export default Spinner;