/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface JsonWebToken
 */
export interface JsonWebToken {
    /**
     * 
     * @type {string}
     * @memberof JsonWebToken
     */
    accessToken?: string | null;

    /**
     * 
     * @type {string}
     * @memberof JsonWebToken
     */
    refreshToken?: string | null;

    /**
     * 
     * @type {number}
     * @memberof JsonWebToken
     */
    expires?: number;

    /**
     * 
     * @type {string}
     * @memberof JsonWebToken
     */
    id?: string;

    /**
     * 
     * @type {Array<string>}
     * @memberof JsonWebToken
     */
    roles?: Array<string> | null;

    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof JsonWebToken
     */
    claims?: { [key: string]: string; } | null;

}

export function JsonWebTokenFromJSON(json: any): JsonWebToken {
    return JsonWebTokenFromJSONTyped(json, false);
}

export function JsonWebTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonWebToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'expires': !exists(json, 'expires') ? undefined : json['expires'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'claims': !exists(json, 'claims') ? undefined : json['claims'],
    };
}

export function JsonWebTokenToJSON(value?: JsonWebToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'expires': value.expires,
        'id': value.id,
        'roles': value.roles,
        'claims': value.claims,
    };
}


