import { grey } from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import baseTheme from './base';


const lightTheme = createMuiTheme({
    props: {

    },
    overrides: {

    },
    palette: {
        primary: {
            main: '#2e3a48',
        },
        secondary: {
            main: 'rgb(238, 241, 243)',
        },
        type: "light",
        background: {
            paper: 'rgb(254, 254, 254)', default: 'rgb(237, 240, 242)', tree: "#d4d6d8"
        },

    }
}, baseTheme)

export default lightTheme