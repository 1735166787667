import { observable, action, IObservableArray, makeObservable } from 'mobx';
import RootStore from "./RootStore"
import { OptionsObject } from 'notistack';



class NotificationStore {
    rootStore: RootStore
    @observable notifications: IObservableArray = observable([])

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action enqueue = (note: { message: string | React.ReactNode, options?: OptionsObject }) => {
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note,
        });
    };

    @action remove = (note) => {
        this.notifications.remove(note);
    };
}

export default NotificationStore