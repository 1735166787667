
import TenantStore from './data/TenantStore';
import RootStore from './RootStore';
import UserStore from './data/UserStore';
import RoleStore from './data/RoleStore';
import DeviceGroupStore from './data/DeviceGroupStore';
import DeviceStore from './data/DeviceStore';
import DeviceModelStore from './data/DeviceModelStore';
import VehicleStore from './data/VehicleStore';


class DataStore {
    rootStore: RootStore


    tenant: TenantStore

    user: UserStore
    role: RoleStore
    device: DeviceStore
    deviceGroup: DeviceGroupStore
    deviceModel: DeviceModelStore
    vehicle: VehicleStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        this.tenant = new TenantStore(rootStore)
        this.user = new UserStore(rootStore)
        this.role = new RoleStore(rootStore)
        this.device = new DeviceStore(rootStore)
        this.deviceGroup = new DeviceGroupStore(rootStore)
        this.deviceModel = new DeviceModelStore(rootStore)
        this.vehicle = new VehicleStore(rootStore)

    }
}


export default DataStore