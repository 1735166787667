
export interface IPages {
    pathname: string,
    oneOfPermission?: string[],
    permission?: string,
    displayNav?: boolean,
    rental?: boolean,
    title: string | boolean,
    children?: IPages[]
}
export const pages: IPages[] = [
    {
        pathname: '404',
        displayNav: false,
        title: "404",
    },
    {
        pathname: 'login',
        displayNav: false,
        title: "common.signIn",
    },
    {
        pathname: 'force',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'relogin',
        displayNav: false,
        title: "common.reSignIn",
    },
    {
        pathname: 'ruleEngine.ruleEditor',
        title: 'ruleEngine.ruleEditor.title',
        displayNav: false,
    },

    {
        pathname: 'dashboard',
        title: "home",
    },
    {
        pathname: 'devicemap',
        title: "devicemap.title",
    },
    {
        pathname: 'mapview',
        title: "mapview.title",
    },
    {
        pathname: 'rawData',
        title: "rawData.title",
    },
    {
        pathname: 'rawDataTable',
        title: "rawDataTable.title",
    },
    {
        pathname: 'rawDataTableCalib',
        title: "rawDataTableCalib.title",
    },
    {
        pathname: 'route.health',
        title: "route.health.title",
    },
    {
        pathname: 'route.green',
        title: "route.green.title",
    },
    /*{
        pathname: 'definitions.report',
        title: 'definitions.report.title',
    },*/
    {
        pathname: 'definitions',
        title: "definitions.title",

        children: [
            // {
            //     pathname: 'definitions.tenant',
            //     title: 'definitions.tenant.title',
            // },

            {
                pathname: 'definitions.user',
                title: 'definitions.user.title',
            },
            {
                pathname: 'definitions.role',
                title: 'definitions.role.title',
            },
            {
                pathname: 'definitions.vehicle',
                title: 'definitions.vehicle.title',
            },
            {
                pathname: 'definitions.device',
                title: 'definitions.device.title',
            },
        ],


    },

    {
        pathname: 'information',
        title: "information.title",
        children: [
            {
                pathname: 'information.about',
                title: 'information.about.title',
            },
            {
                pathname: 'information.health',
                title: 'information.health.title',
            },
            {
                pathname: 'information.sensors',
                title: 'information.sensors.title',
            },
            /* {
                 pathname: 'information.monitor',
                 title: 'information.monitor.title',
             },
             {
                 pathname: 'information.faq',
                 title: 'information.faq.title',
             }*/
        ],


    },

];