/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    ClaimDTO,
    ClaimDTOFromJSON,
    ClaimDTOFromJSONTyped,
    ClaimDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPostDTO
 */
export interface UserPostDTO {
    /**
     * 
     * @type {string}
     * @memberof UserPostDTO
     */
    userName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof UserPostDTO
     */
    email?: string | null;

    /**
     * 
     * @type {string}
     * @memberof UserPostDTO
     */
    phoneNumber?: string | null;

    /**
     * 
     * @type {string}
     * @memberof UserPostDTO
     */
    password?: string | null;

    /**
     * 
     * @type {Array<string>}
     * @memberof UserPostDTO
     */
    roles?: Array<string> | null;

    /**
     * 
     * @type {Array<ClaimDTO>}
     * @memberof UserPostDTO
     */
    claims?: Array<ClaimDTO> | null;

}

export function UserPostDTOFromJSON(json: any): UserPostDTO {
    return UserPostDTOFromJSONTyped(json, false);
}

export function UserPostDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPostDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(ClaimDTOFromJSON)),
    };
}

export function UserPostDTOToJSON(value?: UserPostDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'password': value.password,
        'roles': value.roles,
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(ClaimDTOToJSON)),
    };
}


