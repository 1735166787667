/* eslint-disable react-hooks/rules-of-hooks */
import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceDTO, DevicePostDTO, DeviceUpdateDTO, GetDeviceRequest, SuggestionDTO, DeviceStateDTO, GetDevicesRequest } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useStore } from '../../StoreProvider';

export const useDevice = (id: string, options?: (UseQueryOptions<DeviceDTO>)) => {
    const store = useStore()
    const param: GetDeviceRequest = { id }
    return useQuery<DeviceDTO>(['device', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceApi.getDevice(params as any)
    }, options)
}

export const useDeviceState = (id: string, options?: (UseQueryOptions<DeviceDTO>)) => {
    const store = useStore()
    const param: GetDeviceRequest = { id }
    return useQuery<DeviceStateDTO>(['devicestate', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceApi.getDeviceState(params as any)
    }, options)
}

export const useDeviceCount = (options?: (UseQueryOptions<number>)) => {
    const store = useStore()

    return useQuery<number>(['devicecount'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceApi.getDeviceCount({})
    }, options)
}

export const useDeviceSuggestions = (options?: (UseQueryOptions<SuggestionDTO[]>)) => {
    const store = useStore()
    const param: GetDevicesRequest = {
        deviceGroups: store.dataStore.device.deviceGroupFilter.length === 0 ? undefined : store.dataStore.device.deviceGroupFilter,
        deviceModel: store.dataStore.device.deviceModelFilter === "" ? undefined : store.dataStore.device.deviceModelFilter
    }
    return useQuery<SuggestionDTO[]>(['devicessuggestion', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceApi.getDeviceSuggestions(params as any)
    }, options)
}

export const useDeviceList = (options?: (UseQueryOptions<DeviceDTO[]>)) => {
    const store = useStore()
    const param: GetDevicesRequest = {
        deviceGroups: store.dataStore.device.deviceGroupFilter.length === 0 ? undefined : store.dataStore.device.deviceGroupFilter,
        deviceModel: store.dataStore.device.deviceModelFilter === "" ? undefined : store.dataStore.device.deviceModelFilter
    }
    return useQuery<DeviceDTO[]>(['devices', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceApi.getDevices(params as any)
    }, options)
}

export const useAddDeviceMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()
    const param: GetDevicesRequest = {
        deviceGroups: store.dataStore.device.deviceGroupFilter.length === 0 ? undefined : store.dataStore.device.deviceGroupFilter,
        deviceModel: store.dataStore.device.deviceModelFilter === "" ? undefined : store.dataStore.device.deviceModelFilter
    }
    return useMutation((d: DevicePostDTO) => store.api.deviceApi.postDevice({ devicePostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['devices', param])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['devices', param])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceDTO[]>(['devices', param], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['devices', param], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['devices', param])
        },
    })
}

export const useUpdateDeviceMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()
    const param: GetDevicesRequest = {
        deviceGroups: store.dataStore.device.deviceGroupFilter.length === 0 ? undefined : store.dataStore.device.deviceGroupFilter,
        deviceModel: store.dataStore.device.deviceModelFilter === "" ? undefined : store.dataStore.device.deviceModelFilter
    }
    return useMutation<void, any, DeviceUpdateDTO, any>((d: DeviceUpdateDTO) => store.api.deviceApi.putDevice({ id: d.id!, deviceUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['device', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['device', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceDTO>(['device', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['device', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['device', variables.id])
        },
    })
}


export const useDeleteDeviceMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()
    const param: GetDevicesRequest = {
        deviceGroups: store.dataStore.device.deviceGroupFilter.length === 0 ? undefined : store.dataStore.device.deviceGroupFilter,
        deviceModel: store.dataStore.device.deviceModelFilter === "" ? undefined : store.dataStore.device.deviceModelFilter
    }
    return useMutation((d: string) => store.api.deviceApi.deleteDevice({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['devices', param])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['devices', param])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceDTO[]>(['devices', param], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['devices', param], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['devices', param])
        },
    })
}

class DeviceStore {
    rootStore: RootStore
    @observable deviceGroupFilter: string[] = []
    @observable deviceModelFilter: string = ""
    @observable deviceCount: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }


    /*
        @action deviceTableReport(reportType: string, format: ) {
    
            this.rootStore.api.reportApi.deviceTableReport({
                format: format,
                deviceGroups: this.deviceGroupFilter.length === 0 ? undefined : this.deviceGroupFilter,
                deviceModel: this.deviceModelFilter === "" ? undefined : this.deviceModelFilter,
            }).then((data) => {
                if (data) {
                    saveAs(data, i18n.t("reports.tablo." + reportType) + " " + moment().format("LLL") + (format === .Pdf ? ".pdf" : ".xlsx"))
                }
            })
        }*/
}

export default DeviceStore