/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface RoutingDTO
 */
export interface RoutingDTO {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RoutingDTO
     */
    points?: Array<Array<number>> | null;

}

export function RoutingDTOFromJSON(json: any): RoutingDTO {
    return RoutingDTOFromJSONTyped(json, false);
}

export function RoutingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'points': !exists(json, 'points') ? undefined : json['points'],
    };
}

export function RoutingDTOToJSON(value?: RoutingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'points': value.points,
    };
}


