/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    RouteResponseDTO,
    RouteResponseDTOFromJSON,
    RouteResponseDTOToJSON,
    RoutingDTO,
    RoutingDTOFromJSON,
    RoutingDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface PostRoutingRequest {
    xTenantId?: string;
    routingDTO?: RoutingDTO;
}

/**
 * RouteApi - interface
 * 
 * @export
 * @interface RouteApiInterface
 */
export interface RouteApiInterface {
    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RoutingDTO} [routingDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RouteApiInterface
     */
    postRoutingRaw(requestParameters: PostRoutingRequest): Promise<runtime.ApiResponse<Array<RouteResponseDTO>>>;

    /**
     */
    postRouting(requestParameters: PostRoutingRequest): Promise<Array<RouteResponseDTO>>;

}

/**
 * 
 */
export class RouteApi extends runtime.BaseAPI implements RouteApiInterface {

    /**
     */
    async postRoutingRaw(requestParameters: PostRoutingRequest): Promise<runtime.ApiResponse<Array<RouteResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Route/routing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoutingDTOToJSON(requestParameters.routingDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RouteResponseDTOFromJSON));
    }

    /**
     */
    async postRouting(requestParameters: PostRoutingRequest): Promise<Array<RouteResponseDTO>> {
        const response = await this.postRoutingRaw(requestParameters);
        return await response.value();
    }

}
