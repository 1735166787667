/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    TelemetryType,
    TelemetryTypeFromJSON,
    TelemetryTypeFromJSONTyped,
    TelemetryTypeToJSON,
} from './';

/**
 * Iot.Data.Models.RuleEngineAlarm kuralÄ±
 * @export
 * @interface RuleEngineAlarmCondition
 */
export interface RuleEngineAlarmCondition {
    /**
     * Kural deÄŸeri
     * @type {string}
     * @memberof RuleEngineAlarmCondition
     */
    value?: string | null;

    /**
     * Kuralda uygulanacak operator
     * @type {string}
     * @memberof RuleEngineAlarmCondition
     */
    operator?: string | null;

    /**
     * KuralÄ±n uygulanacaÄŸÄ± sensor verisi
     * @type {string}
     * @memberof RuleEngineAlarmCondition
     */
    typeKey?: string | null;

    /**
     * 
     * @type {TelemetryType}
     * @memberof RuleEngineAlarmCondition
     */
    telemetryType?: TelemetryType;

}

export function RuleEngineAlarmConditionFromJSON(json: any): RuleEngineAlarmCondition {
    return RuleEngineAlarmConditionFromJSONTyped(json, false);
}

export function RuleEngineAlarmConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'operator': !exists(json, 'operator') ? undefined : json['operator'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'telemetryType': !exists(json, 'telemetryType') ? undefined : TelemetryTypeFromJSON(json['telemetryType']),
    };
}

export function RuleEngineAlarmConditionToJSON(value?: RuleEngineAlarmCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'operator': value.operator,
        'typeKey': value.typeKey,
        'telemetryType': TelemetryTypeToJSON(value.telemetryType),
    };
}


