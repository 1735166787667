import { observable, action, computed, IObservableArray, toJS, makeObservable } from 'mobx';
import RootStore from "./RootStore"
import { JsonWebToken, LoginDto, TenantDTO, TenantDTOFromJSON, } from '../api';
import { RouterState, createRouterState } from 'mobx-state-router';
import jwtDecode from "jwt-decode";
import config from '../config';
import { pages, IPages } from '../pages';
import DataStore from './DataStore';
import * as Sentry from '@sentry/browser'
import i18n from '../i18n';


function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}
class AuthStore {
    rootStore: RootStore
    @observable auth = false
    @observable jwt: any | undefined
    @observable rememberMe = false
    @observable claims: IObservableArray<any> = observable([])
    @observable tenant: TenantDTO | null = null



    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
        this.rootStore.api.localForage.getItem(config.tokenKey + "-claims").then((data: any) => {
            this.claims = data ? data : observable([])
        })
        const oldToken = localStorage.getItem(config.tokenKey)
        const tenantLS = localStorage.getItem(config.tokenKey + "-tenant")
        if (tenantLS) {
            this.tenant = TenantDTOFromJSON(JSON.parse(tenantLS))
        }
        //TODO: fecth user info when auto login
        //TODO: logut when token expired
        if (oldToken) {
            this.auth = true
            this.jwt = jwtDecode(oldToken)
            this.getOwnTenant()
            this.rootStore.signalR.connectSignalR()

            /*
            this.rootStore.api.account2.getClaims().then((data) => {
                if (data) {
                    this.setClaims(data)
                    this.setSentryScope(toJS(this.jwt!))
                }

            })

*/
        }
    }
    @action getOwnTenant = async () => {
        var response = await this.rootStore.api.tenantApi.getUserOwnTenant({})
        this.tenant = response
        localStorage.setItem(config.tokenKey + "-tenant", JSON.stringify(response))
    }

    @action setClaims = (data: any[]) => {
        this.rootStore.api.localForage.setItem(config.tokenKey + "-claims", data)
        //@ts-ignore
        this.claims = data
    }

    @action checkExpire = (): boolean => {
        return this.jwt !== undefined && (new Date().getTime() / 1000 > this.jwt!.exp)
    }

    @action hasPermission = (perm: string) => {
        return this.claims.some(e => e.value === perm)
    }

    @action hasOneOfPermission = (permArr: string[]) => {
        if (permArr) {
            return this.claims.some(e => permArr.some(t => t === e.value))
        } else {
            return false
        }
    }

    @computed get availablePages() {
        let p = []
        const hasPermission = (perm: string) => {
            return this.claims.some(e => e.value === perm)
        }

        const hasOneOfPermission = (permArr: string[]) => {
            if (permArr) {
                return this.claims.some(e => permArr.some(t => t === e.value))
            } else {
                return false
            }
        }
        const reduceRoutes = (pages: IPages[]) => {
            return pages.reduce(
                (items, page) => reduceChildRoutes({ items, page }),
                [],
            )
        }

        const reduceChildRoutes = ({ items, page }) => {
            if (page.displayNav === false || (page.permission && !hasPermission(page.permission)) || (page.oneOfPermission && !hasOneOfPermission(page.oneOfPermission))) {
                return items;
            }
            if (page.children && page.children.length > 1) {
                let childs = page.children
                page.children = []
                childs.forEach(p => {
                    if (p.displayNav === false || (p.permission && !hasPermission(p.permission)) || (p.oneOfPermission && !hasOneOfPermission(p.oneOfPermission))) {

                    } else {
                        page.children.push(p)
                    }
                })
                page = page.children && page.children.length === 1 ? page.children[0] : page;

                items.push(page)
            } else {
                page = page.children && page.children.length === 1 ? page.children[0] : page;
                items.push(page)
            }
            return items
        }
        p = reduceRoutes(pages)
        return p
    }

    @action login = async (params: LoginDto) => {
        let response: JsonWebToken | null = null
        try {
            response = await this.rootStore.api.userApi.login({ loginDto: params })
        } catch (error) {
            if (error.status && error.status === 500) {

                this.rootStore.notification.enqueue({
                    message: i18n.t("common.errors.serverError"),
                    options: {
                        variant: 'error',
                        preventDuplicate: true
                    }
                })

            } else if (error.status && error.status === 404) {


            } else {
                this.rootStore.notification.enqueue({
                    message: i18n.t("common.errors.connectionError"),
                    options: {
                        variant: 'error',
                        preventDuplicate: true
                    }
                })
            }
            this.auth = false
            return true
        }
        if (response) {
            await this.rootStore.signalR.connectSignalR()
            this.rootStore.api.setTokenDto(response)

            localStorage.setItem(config.tokenKey, response.accessToken!)
            this.getOwnTenant()
            this.jwt = jwtDecode(response.accessToken!)
            this.setSentryScope(toJS(this.jwt!))

            this.auth = true
            return false
        }
        return true
    }
    /*
        @action externalLogin = async (params: ExternalLoginRequest) => {
            let response
            try {
                response = await this.rootStore.api.account.externalLogin(params)
            } catch (error) {
                if (error.status && error.status === 500) {
    
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.serverError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
    
                } else if (error.status && error.status === 404) {
    
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.externalLoginNotFound"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
    
                } else {
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.connectionError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                }
                this.auth = false
                await this.logout()
            }
    
            if (response) {
    
                this.rootStore.api.setTokenDto(response)
    
                localStorage.setItem(config.tokenKey, response.refreshToken)
    
                this.jwt = jwtDecode(response.token)
                this.setSentryScope(toJS(this.jwt!))
                this.auth = true
    
            }
            return !this.auth
        }
    */
    @action setJwt = (jwt) => {
        this.jwt = jwt
    }

    setSentryScope = (jwt) => {
        Sentry.configureScope((scope) => {
            scope.setUser({
                "username": jwt.username,
                "id": jwt.UserId
            });
            scope.setExtra("tenantId", jwt.TenantId);
            scope.setTag("tenantId", jwt.TenantId);
        });
    }


    @action logout = () => {
        this.rootStore.api.localForage.removeItem(config.tokenKey + "-claims")
        this.rootStore.dataStore = new DataStore(this.rootStore)
        localStorage.removeItem(config.tokenKey)
        this.auth = false
        this.jwt = undefined
        //@ts-ignore
        this.setSentryScope({})
        this.rootStore.api.token = "----"
        let toState = createRouterState("login")
        this.rootStore.routing.goToState(toState)
    }

    @computed get canAudit() {
        return this.hasPermission("common.audit")
    }

    @computed get isSystemAdmin() {
        return this.jwt && this.jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "SystemAdministrator"
    }
}

export default AuthStore