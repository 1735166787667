/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    TelemetryType,
    TelemetryTypeFromJSON,
    TelemetryTypeFromJSONTyped,
    TelemetryTypeToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     * 
     * @type {Date}
     * @memberof Sensor
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof Sensor
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof Sensor
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    id?: string;

    /**
     * 
     * @type {Tenant}
     * @memberof Sensor
     */
    tenant?: Tenant;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    typeKey?: string | null;

    /**
     * 
     * @type {TelemetryType}
     * @memberof Sensor
     */
    telemetryType?: TelemetryType;

    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    tenantId?: string;

}

export function SensorFromJSON(json: any): Sensor {
    return SensorFromJSONTyped(json, false);
}

export function SensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sensor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'telemetryType': !exists(json, 'telemetryType') ? undefined : TelemetryTypeFromJSON(json['telemetryType']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function SensorToJSON(value?: Sensor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'tenant': TenantToJSON(value.tenant),
        'name': value.name,
        'description': value.description,
        'typeKey': value.typeKey,
        'telemetryType': TelemetryTypeToJSON(value.telemetryType),
        'tenantId': value.tenantId,
    };
}


