/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    SensorDataDTO,
    SensorDataDTOFromJSON,
    SensorDataDTOToJSON,
    SensorDataDTOCalib,
    SensorDataDTOCalibFromJSON,
    SensorDataDTOCalibToJSON,
    TimeSeriesDataDTOListPagedResponse,
    TimeSeriesDataDTOListPagedResponseFromJSON,
    TimeSeriesDataDTOListPagedResponseToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetTimeSeriesDataRequest {
    deviceId?: string;
    sensorType?: Array<string>;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    deviceModelId?: string;
    pageNumber?: number;
    pageSize?: number;
    xTenantId?: string;
}

export interface GetTimeSeriesDataCekipRequest {
    deviceId?: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    period?: number;
    deviceModelId?: string;
    xTenantId?: string;
}

export interface GetTimeSeriesDataCekipCalibRequest {
    deviceId?: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    period?: number;
    deviceModelId?: string;
    xTenantId?: string;
}

/**
 * TimeSeriesDataApi - interface
 * 
 * @export
 * @interface TimeSeriesDataApiInterface
 */
export interface TimeSeriesDataApiInterface {
    /**
     * 
     * @param {string} [deviceId] 
     * @param {Array<string>} [sensorType] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [deviceModelId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSeriesDataApiInterface
     */
    getTimeSeriesDataRaw(requestParameters: GetTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>>;

    /**
     */
    getTimeSeriesData(requestParameters: GetTimeSeriesDataRequest): Promise<TimeSeriesDataDTOListPagedResponse>;

    /**
     * 
     * @param {string} [deviceId] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {number} [period] 
     * @param {string} [deviceModelId] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSeriesDataApiInterface
     */
    getTimeSeriesDataCekipRaw(requestParameters: GetTimeSeriesDataCekipRequest): Promise<runtime.ApiResponse<Array<SensorDataDTO>>>;

    /**
     */
    getTimeSeriesDataCekip(requestParameters: GetTimeSeriesDataCekipRequest): Promise<Array<SensorDataDTO>>;

    /**
     * 
     * @param {string} [deviceId] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {number} [period] 
     * @param {string} [deviceModelId] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSeriesDataApiInterface
     */
    getTimeSeriesDataCekipCalibRaw(requestParameters: GetTimeSeriesDataCekipCalibRequest): Promise<runtime.ApiResponse<Array<SensorDataDTOCalib>>>;

    /**
     */
    getTimeSeriesDataCekipCalib(requestParameters: GetTimeSeriesDataCekipCalibRequest): Promise<Array<SensorDataDTOCalib>>;

}

/**
 * 
 */
export class TimeSeriesDataApi extends runtime.BaseAPI implements TimeSeriesDataApiInterface {

    /**
     */
    async getTimeSeriesDataRaw(requestParameters: GetTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.sensorType) {
            queryParameters['sensorType'] = requestParameters.sensorType;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TimeSeriesData/GetTimeSeriesData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesDataDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTimeSeriesData(requestParameters: GetTimeSeriesDataRequest): Promise<TimeSeriesDataDTOListPagedResponse> {
        const response = await this.getTimeSeriesDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTimeSeriesDataCekipRaw(requestParameters: GetTimeSeriesDataCekipRequest): Promise<runtime.ApiResponse<Array<SensorDataDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TimeSeriesData/GetTimeSeriesDataCekip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SensorDataDTOFromJSON));
    }

    /**
     */
    async getTimeSeriesDataCekip(requestParameters: GetTimeSeriesDataCekipRequest): Promise<Array<SensorDataDTO>> {
        const response = await this.getTimeSeriesDataCekipRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTimeSeriesDataCekipCalibRaw(requestParameters: GetTimeSeriesDataCekipCalibRequest): Promise<runtime.ApiResponse<Array<SensorDataDTOCalib>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TimeSeriesData/GetTimeSeriesDataCekipCalib`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SensorDataDTOCalibFromJSON));
    }

    /**
     */
    async getTimeSeriesDataCekipCalib(requestParameters: GetTimeSeriesDataCekipCalibRequest): Promise<Array<SensorDataDTOCalib>> {
        const response = await this.getTimeSeriesDataCekipCalibRaw(requestParameters);
        return await response.value();
    }

}
