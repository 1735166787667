import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceModelDTO, DeviceModelPostDTO, DeviceModelUpdateDTO, SuggestionDTO } from '../../api';
import { useStore } from '../../StoreProvider';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';


export const useDeviceModelSuggestions = (options?: (UseQueryOptions<SuggestionDTO[]>)) => {
    const store = useStore()

    return useQuery<SuggestionDTO[]>(['deviceModelSuggestions'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceModelApi.getDeviceModelSuggestions({})
    }, options)
}

export const useDeviceModelList = (options?: (UseQueryOptions<DeviceModelDTO[]>)) => {
    const store = useStore()

    return useQuery<DeviceModelDTO[]>(['deviceModels'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceModelApi.getDeviceModels({})
    }, options)
}

export const useAddDeviceModelMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: DeviceModelPostDTO) => store.api.deviceModelApi.postDeviceModel({ deviceModelPostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceModels'])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceModels'])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceModelDTO[]>(['deviceModels'], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceModels'], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['deviceModels'])
        },
    })
}

export const useUpdateDeviceModelMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation<void, any, DeviceModelUpdateDTO, any>((d: DeviceModelUpdateDTO) => store.api.deviceModelApi.putDeviceModel({ id: d.id!, deviceModelUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceModel', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceModel', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceModelDTO>(['deviceModel', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceModel', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['deviceModel', variables.id])
        },
    })
}


export const useDeleteDeviceModelMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: string) => store.api.deviceModelApi.deleteDeviceModel({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceModels'])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceModels'])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceModelDTO[]>(['deviceModels'], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceModels'], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['deviceModels'])
        },
    })
}

class DeviceModelStore {
    rootStore: RootStore
    //to fix mobs errors
    @observable empty: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }


    /*
        @action deviceModelTableReport(reportType: string, format: ) {
    
            this.rootStore.api.reportApi.deviceModelTableReport({
                format: format
            }).then((data) => {
                if (data) {
                    saveAs(data, i18n.t("reports.tablo." + reportType) + " " + moment().format("LLL") + (format === .Pdf ? ".pdf" : ".xlsx"))
                }
            })
        }*/
}

export default DeviceModelStore