import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'
import { IBaseProps } from './types'
import { Paper, Button } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            width: 'auto',
            display: 'block', // Fix IE 11 issue.
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        },
    });

class ErrorBoundary extends React.Component<WithStyles<typeof styles> & IBaseProps, { hasError: boolean, eventId: any }> {
    constructor(props) {
        super(props)
        this.state = { hasError: false, eventId: null }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        console.log(error, info)
        Sentry.withScope(scope => {
            scope.setExtras(info);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId })
        });
    }

    render() {
        const t = this.props.t!
        const classes = this.props.classes
        if (this.state.hasError) {
            return (
                <main className={classes.main}>

                    <Paper className={classes.paper}>

                        <h4 className="pt-3">{t('somethingBadHappened')}</h4>
                        <Button
                            onClick={() => {
                                window.location.href = '/'
                            }}
                        >
                            {t('goHomePage')}
                        </Button>
                        <Button
                            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId, lang: "tr" })}
                        >
                            Hatayı bildir
                  </Button>
                    </Paper>
                </main>
            )
        }
        return this.props.children
    }
}
//@ts-ignore
export default withTranslation("main")(withStyles(styles)(ErrorBoundary))
