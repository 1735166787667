/* eslint-disable react-hooks/rules-of-hooks */
import { observable, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { VehicleDTO, VehiclePostDTO, VehicleUpdateDTO, GetVehicleRequest, DeviceStateDTO } from '../../api';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useStore } from '../../StoreProvider';

export const useVehicle = (id: string, options?: (UseQueryOptions<VehicleDTO>)) => {
    const store = useStore()
    const param: GetVehicleRequest = { id }
    return useQuery<VehicleDTO>(['vehicle', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.vehicleApi.getVehicle(params as any)
    }, options)
}

export const useVehicleState = (id: string, options?: (UseQueryOptions<DeviceStateDTO>)) => {
    const store = useStore()
    const param: GetVehicleRequest = { id }
    return useQuery<DeviceStateDTO>(['vehiclestate', param], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.vehicleApi.getVehicleState(params as any)
    }, options)
}
export const useVehicleCount = (options?: (UseQueryOptions<number>)) => {
    const store = useStore()

    return useQuery<number>(['vehiclecount'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.vehicleApi.getVehicleCount({})
    }, options)
}

export const useVehicleList = (options?: (UseQueryOptions<VehicleDTO[]>)) => {
    const store = useStore()

    return useQuery<VehicleDTO[]>(["vehicles"], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.vehicleApi.getVehicles({})
    }, options)
}

export const useAddVehicleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: VehiclePostDTO) => store.api.vehicleApi.postVehicle({ vehiclePostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(["vehicles"])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(["vehicles"])

            // Optimistically update to the new value
            queryClient.setQueryData<VehicleDTO[]>(["vehicles"], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(["vehicles"], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(["vehicles"])
        },
    })
}

export const useUpdateVehicleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation<void, any, VehicleUpdateDTO, any>((d: VehicleUpdateDTO) => store.api.vehicleApi.putVehicle({ id: d.id!, vehicleUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['vehicle', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['vehicle', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<VehicleDTO>(['vehicle', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['vehicle', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['vehicle', variables.id])
        },
    })
}

export const useDeleteVehicleMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: string) => store.api.vehicleApi.deleteVehicle({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(["vehicles"])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(["vehicles"])

            // Optimistically update to the new value
            queryClient.setQueryData<VehicleDTO[]>(["vehicles"], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(["vehicles"], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(["vehicles"])
        },
    })
}

class VehicleStore {
    rootStore: RootStore
    @observable vehicleGroupFilter: string[] = []
    @observable vehicleModelFilter: string = ""
    @observable vehicleCount: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }
    /*
        @action vehicleTableReport(reportType: string, format: ) {
    
            this.rootStore.api.reportApi.vehicleTableReport({
                format: format,
                vehicleGroups: this.vehicleGroupFilter.length === 0 ? undefined : this.vehicleGroupFilter,
                vehicleModel: this.vehicleModelFilter === "" ? undefined : this.vehicleModelFilter,
            }).then((data) => {
                if (data) {
                    saveAs(data, i18n.t("reports.tablo." + reportType) + " " + moment().format("LLL") + (format === .Pdf ? ".pdf" : ".xlsx"))
                }
            })
        }*/
}

export default VehicleStore