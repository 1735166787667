/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface SensorDataDTO
 */
export interface SensorDataDTO {
    /**
     * 
     * @type {string}
     * @memberof SensorDataDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {Date}
     * @memberof SensorDataDTO
     */
    bucketStart?: moment.Moment;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    airTempRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    airHumRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    pm2p5Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    co2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    pm10Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    h2SRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    sO2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    nH3Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    nO2Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    coRaw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    nO2Mics4514Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    coMics4514Raw?: number | null;

    /**
     * 
     * @type {number}
     * @memberof SensorDataDTO
     */
    o3Raw?: number | null;

}

export function SensorDataDTOFromJSON(json: any): SensorDataDTO {
    return SensorDataDTOFromJSONTyped(json, false);
}

export function SensorDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'bucketStart': !exists(json, 'bucketStart') ? undefined : (moment(json['bucketStart'])),

        'airTempRaw': !exists(json, 'airTempRaw') ? undefined : json['airTempRaw'],
        'airHumRaw': !exists(json, 'airHumRaw') ? undefined : json['airHumRaw'],
        'pm2p5Raw': !exists(json, 'pm2p5Raw') ? undefined : json['pm2p5Raw'],
        'co2Raw': !exists(json, 'co2Raw') ? undefined : json['co2Raw'],
        'pm10Raw': !exists(json, 'pm10Raw') ? undefined : json['pm10Raw'],
        'h2SRaw': !exists(json, 'h2SRaw') ? undefined : json['h2SRaw'],
        'sO2Raw': !exists(json, 'sO2Raw') ? undefined : json['sO2Raw'],
        'nH3Raw': !exists(json, 'nH3Raw') ? undefined : json['nH3Raw'],
        'nO2Raw': !exists(json, 'nO2Raw') ? undefined : json['nO2Raw'],
        'coRaw': !exists(json, 'coRaw') ? undefined : json['coRaw'],
        'nO2Mics4514Raw': !exists(json, 'nO2Mics4514Raw') ? undefined : json['nO2Mics4514Raw'],
        'coMics4514Raw': !exists(json, 'coMics4514Raw') ? undefined : json['coMics4514Raw'],
        'o3Raw': !exists(json, 'o3Raw') ? undefined : json['o3Raw'],
    };
}

export function SensorDataDTOToJSON(value?: SensorDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'bucketStart': value.bucketStart === undefined ? undefined : (value.bucketStart.toISOString()),
        'airTempRaw': value.airTempRaw,
        'airHumRaw': value.airHumRaw,
        'pm2p5Raw': value.pm2p5Raw,
        'co2Raw': value.co2Raw,
        'pm10Raw': value.pm10Raw,
        'h2SRaw': value.h2SRaw,
        'sO2Raw': value.sO2Raw,
        'nH3Raw': value.nH3Raw,
        'nO2Raw': value.nO2Raw,
        'coRaw': value.coRaw,
        'nO2Mics4514Raw': value.nO2Mics4514Raw,
        'coMics4514Raw': value.coMics4514Raw,
        'o3Raw': value.o3Raw,
    };
}


