/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    CommandToDeviceLogDTO,
    CommandToDeviceLogDTOFromJSON,
    CommandToDeviceLogDTOToJSON,
    CommandToDevicePostDTO,
    CommandToDevicePostDTOFromJSON,
    CommandToDevicePostDTOToJSON,
    Device,
    DeviceFromJSON,
    DeviceToJSON,
    DeviceDTO,
    DeviceDTOFromJSON,
    DeviceDTOToJSON,
    DevicePostDTO,
    DevicePostDTOFromJSON,
    DevicePostDTOToJSON,
    DeviceStateDTO,
    DeviceStateDTOFromJSON,
    DeviceStateDTOToJSON,
    DeviceUpdateDTO,
    DeviceUpdateDTOFromJSON,
    DeviceUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    TimeSeriesDataDTO,
    TimeSeriesDataDTOFromJSON,
    TimeSeriesDataDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteDeviceRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceCountRequest {
    xTenantId?: string;
}

export interface GetDeviceStateRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceSuggestionsRequest {
    deviceGroups?: Array<string>;
    deviceModel?: string;
    xTenantId?: string;
}

export interface GetDevicesRequest {
    deviceGroups?: Array<string>;
    deviceModel?: string;
    xTenantId?: string;
}

export interface GetLatestTelemetryRequest {
    id: string;
    typeKey?: string;
    xTenantId?: string;
}

export interface GetTotalDeviceCountRequest {
    xTenantId?: string;
}

export interface PostCommandTodeviceRequest {
    id: string;
    xTenantId?: string;
    commandToDevicePostDTO?: CommandToDevicePostDTO;
}

export interface PostDeviceRequest {
    xTenantId?: string;
    devicePostDTO?: DevicePostDTO;
}

export interface PutDeviceRequest {
    id: string;
    xTenantId?: string;
    deviceUpdateDTO?: DeviceUpdateDTO;
}

/**
 * DeviceApi - interface
 * 
 * @export
 * @interface DeviceApiInterface
 */
export interface DeviceApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deleteDeviceRaw(requestParameters: DeleteDeviceRequest): Promise<runtime.ApiResponse<Device>>;

    /**
     */
    deleteDevice(requestParameters: DeleteDeviceRequest): Promise<Device>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDeviceRaw(requestParameters: GetDeviceRequest): Promise<runtime.ApiResponse<DeviceDTO>>;

    /**
     */
    getDevice(requestParameters: GetDeviceRequest): Promise<DeviceDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDeviceCountRaw(requestParameters: GetDeviceCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     */
    getDeviceCount(requestParameters: GetDeviceCountRequest): Promise<number>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDeviceStateRaw(requestParameters: GetDeviceStateRequest): Promise<runtime.ApiResponse<DeviceStateDTO>>;

    /**
     */
    getDeviceState(requestParameters: GetDeviceStateRequest): Promise<DeviceStateDTO>;

    /**
     * 
     * @param {Array<string>} [deviceGroups] 
     * @param {string} [deviceModel] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDeviceSuggestionsRaw(requestParameters: GetDeviceSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     */
    getDeviceSuggestions(requestParameters: GetDeviceSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @param {Array<string>} [deviceGroups] 
     * @param {string} [deviceModel] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getDevicesRaw(requestParameters: GetDevicesRequest): Promise<runtime.ApiResponse<Array<DeviceDTO>>>;

    /**
     */
    getDevices(requestParameters: GetDevicesRequest): Promise<Array<DeviceDTO>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [typeKey] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getLatestTelemetryRaw(requestParameters: GetLatestTelemetryRequest): Promise<runtime.ApiResponse<Array<TimeSeriesDataDTO>>>;

    /**
     */
    getLatestTelemetry(requestParameters: GetLatestTelemetryRequest): Promise<Array<TimeSeriesDataDTO>>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getTotalDeviceCountRaw(requestParameters: GetTotalDeviceCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     */
    getTotalDeviceCount(requestParameters: GetTotalDeviceCountRequest): Promise<number>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {CommandToDevicePostDTO} [commandToDevicePostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    postCommandTodeviceRaw(requestParameters: PostCommandTodeviceRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTO>>;

    /**
     */
    postCommandTodevice(requestParameters: PostCommandTodeviceRequest): Promise<CommandToDeviceLogDTO>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DevicePostDTO} [devicePostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    postDeviceRaw(requestParameters: PostDeviceRequest): Promise<runtime.ApiResponse<DeviceDTO>>;

    /**
     */
    postDevice(requestParameters: PostDeviceRequest): Promise<DeviceDTO>;

    /**
     * 
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceUpdateDTO} [deviceUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    putDeviceRaw(requestParameters: PutDeviceRequest): Promise<runtime.ApiResponse<void>>;

    /**
     */
    putDevice(requestParameters: PutDeviceRequest): Promise<void>;

}

/**
 * 
 */
export class DeviceApi extends runtime.BaseAPI implements DeviceApiInterface {

    /**
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest): Promise<runtime.ApiResponse<Device>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceFromJSON(jsonValue));
    }

    /**
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest): Promise<Device> {
        const response = await this.deleteDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceRaw(requestParameters: GetDeviceRequest): Promise<runtime.ApiResponse<DeviceDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDTOFromJSON(jsonValue));
    }

    /**
     */
    async getDevice(requestParameters: GetDeviceRequest): Promise<DeviceDTO> {
        const response = await this.getDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceCountRaw(requestParameters: GetDeviceCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/deviceCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getDeviceCount(requestParameters: GetDeviceCountRequest): Promise<number> {
        const response = await this.getDeviceCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceStateRaw(requestParameters: GetDeviceStateRequest): Promise<runtime.ApiResponse<DeviceStateDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeviceState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}/deviceState`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceStateDTOFromJSON(jsonValue));
    }

    /**
     */
    async getDeviceState(requestParameters: GetDeviceStateRequest): Promise<DeviceStateDTO> {
        const response = await this.getDeviceStateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDeviceSuggestionsRaw(requestParameters: GetDeviceSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceGroups) {
            queryParameters['deviceGroups'] = requestParameters.deviceGroups;
        }

        if (requestParameters.deviceModel !== undefined) {
            queryParameters['deviceModel'] = requestParameters.deviceModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     */
    async getDeviceSuggestions(requestParameters: GetDeviceSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getDeviceSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDevicesRaw(requestParameters: GetDevicesRequest): Promise<runtime.ApiResponse<Array<DeviceDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceGroups) {
            queryParameters['deviceGroups'] = requestParameters.deviceGroups;
        }

        if (requestParameters.deviceModel !== undefined) {
            queryParameters['deviceModel'] = requestParameters.deviceModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceDTOFromJSON));
    }

    /**
     */
    async getDevices(requestParameters: GetDevicesRequest): Promise<Array<DeviceDTO>> {
        const response = await this.getDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLatestTelemetryRaw(requestParameters: GetLatestTelemetryRequest): Promise<runtime.ApiResponse<Array<TimeSeriesDataDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLatestTelemetry.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeKey !== undefined) {
            queryParameters['typeKey'] = requestParameters.typeKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}/latestTelemetry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimeSeriesDataDTOFromJSON));
    }

    /**
     */
    async getLatestTelemetry(requestParameters: GetLatestTelemetryRequest): Promise<Array<TimeSeriesDataDTO>> {
        const response = await this.getLatestTelemetryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTotalDeviceCountRaw(requestParameters: GetTotalDeviceCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/totalDeviceCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getTotalDeviceCount(requestParameters: GetTotalDeviceCountRequest): Promise<number> {
        const response = await this.getTotalDeviceCountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postCommandTodeviceRaw(requestParameters: PostCommandTodeviceRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postCommandTodevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}/command`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommandToDevicePostDTOToJSON(requestParameters.commandToDevicePostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandToDeviceLogDTOFromJSON(jsonValue));
    }

    /**
     */
    async postCommandTodevice(requestParameters: PostCommandTodeviceRequest): Promise<CommandToDeviceLogDTO> {
        const response = await this.postCommandTodeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async postDeviceRaw(requestParameters: PostDeviceRequest): Promise<runtime.ApiResponse<DeviceDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DevicePostDTOToJSON(requestParameters.devicePostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDTOFromJSON(jsonValue));
    }

    /**
     */
    async postDevice(requestParameters: PostDeviceRequest): Promise<DeviceDTO> {
        const response = await this.postDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async putDeviceRaw(requestParameters: PutDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceUpdateDTOToJSON(requestParameters.deviceUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putDevice(requestParameters: PutDeviceRequest): Promise<void> {
        await this.putDeviceRaw(requestParameters);
    }

}
