import { observable, action, computed, toJS, makeObservable } from 'mobx';
import RootStore from "./RootStore"
import { updateLocale } from '../utils/moment';
import i18n from '../i18n';
import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme';
import { red } from '@material-ui/core/colors';
import * as Sentry from '@sentry/browser'
import themes from '../themes';



class UiStore {
    rootStore: RootStore
    @observable colorTheme: string = "green2"

    @observable lng = "tr"


    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
        this.rootStore.api.localForage.getItem("colorTheme").then((oldColor) => {
            if (oldColor === "dark") {
                this.colorTheme = "dark"
            }
            Sentry.configureScope((scope) => {
                scope.setTag("color_theme", toJS(this.colorTheme));
            });
        })

        updateLocale()
    }

    @computed get theme(): Theme {
        return themes[this.colorTheme]
    }

    @action setLanguage = (lng: string) => {
        if (this.lng !== lng) {
            updateLocale()
            i18n.changeLanguage(lng, (err, t) => {
                updateLocale()
                if (err) return console.error(err, 'i18n changeLanguage')
            })
        }
        this.lng = lng
        Sentry.configureScope((scope) => {
            scope.setTag("page_locale", lng);
        });
    };

    @action toggleColorTheme = () => {
        this.colorTheme = this.colorTheme === 'light' ? 'dark' : 'light';
        this.rootStore.api.localForage.setItem("colorTheme", this.colorTheme)
        Sentry.configureScope((scope) => {
            scope.setTag("color_theme", toJS(this.colorTheme));
        });
    };
    @action setColorTheme = (theme: string) => {
        this.colorTheme = theme;
        this.rootStore.api.localForage.setItem("colorTheme", this.colorTheme)
        Sentry.configureScope((scope) => {
            scope.setTag("color_theme", toJS(this.colorTheme));
        });
    };
}

export default UiStore