/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    TelemetryDTO,
    TelemetryDTOFromJSON,
    TelemetryDTOFromJSONTyped,
    TelemetryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface VehicleTelemetryDTO
 */
export interface VehicleTelemetryDTO {
    /**
     * 
     * @type {string}
     * @memberof VehicleTelemetryDTO
     */
    vehicleId?: string;

    /**
     * 
     * @type {string}
     * @memberof VehicleTelemetryDTO
     */
    vehiclePlate?: string | null;

    /**
     * 
     * @type {string}
     * @memberof VehicleTelemetryDTO
     */
    vehicleNickname?: string | null;

    /**
     * 
     * @type {string}
     * @memberof VehicleTelemetryDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {number}
     * @memberof VehicleTelemetryDTO
     */
    lat?: number | null;

    /**
     * 
     * @type {number}
     * @memberof VehicleTelemetryDTO
     */
    lon?: number | null;

    /**
     * 
     * @type {boolean}
     * @memberof VehicleTelemetryDTO
     */
    ignition?: boolean;

    /**
     * 
     * @type {Date}
     * @memberof VehicleTelemetryDTO
     */
    timestamp?: moment.Moment | null;

    /**
     * 
     * @type {Date}
     * @memberof VehicleTelemetryDTO
     */
    transportTimestamp?: moment.Moment | null;

    /**
     * 
     * @type {Array<TelemetryDTO>}
     * @memberof VehicleTelemetryDTO
     */
    other?: Array<TelemetryDTO> | null;

}

export function VehicleTelemetryDTOFromJSON(json: any): VehicleTelemetryDTO {
    return VehicleTelemetryDTOFromJSONTyped(json, false);
}

export function VehicleTelemetryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleTelemetryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'vehiclePlate': !exists(json, 'vehiclePlate') ? undefined : json['vehiclePlate'],
        'vehicleNickname': !exists(json, 'vehicleNickname') ? undefined : json['vehicleNickname'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'ignition': !exists(json, 'ignition') ? undefined : json['ignition'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (json['timestamp'] === null ? null : moment(json['timestamp'])),

        'transportTimestamp': !exists(json, 'transportTimestamp') ? undefined : (json['transportTimestamp'] === null ? null : moment(json['transportTimestamp'])),

        'other': !exists(json, 'other') ? undefined : (json['other'] === null ? null : (json['other'] as Array<any>).map(TelemetryDTOFromJSON)),
    };
}

export function VehicleTelemetryDTOToJSON(value?: VehicleTelemetryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleId': value.vehicleId,
        'vehiclePlate': value.vehiclePlate,
        'vehicleNickname': value.vehicleNickname,
        'deviceId': value.deviceId,
        'lat': value.lat,
        'lon': value.lon,
        'ignition': value.ignition,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp === null ? null : value.timestamp.toISOString()),
        'transportTimestamp': value.transportTimestamp === undefined ? undefined : (value.transportTimestamp === null ? null : value.transportTimestamp.toISOString()),
        'other': value.other === undefined ? undefined : (value.other === null ? null : (value.other as Array<any>).map(TelemetryDTOToJSON)),
    };
}


