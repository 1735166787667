import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceGroupDTO, DeviceGroupPostDTO, DeviceGroupUpdateDTO, SuggestionDTO } from '../../api';
import { useStore } from '../../StoreProvider';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';



export const useDeviceGroupSuggestions = (options?: (UseQueryOptions<SuggestionDTO[]>)) => {
    const store = useStore()

    return useQuery<SuggestionDTO[]>(['deviceGroupSuggestions'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceGroupApi.getDeviceGroupSuggestions({})
    }, options)
}

export const useDeviceGroupList = (options?: (UseQueryOptions<DeviceGroupDTO[]>)) => {
    const store = useStore()

    return useQuery<DeviceGroupDTO[]>(['deviceGroups'], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.deviceGroupApi.getDeviceGroups({})
    }, options)
}

export const useAddDeviceGroupMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: DeviceGroupPostDTO) => store.api.deviceGroupApi.postDeviceGroup({ deviceGroupPostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceGroups'])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceGroups'])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceGroupDTO[]>(['deviceGroups'], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceGroups'], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['deviceGroups'])
        },
    })
}

export const useUpdateDeviceGroupMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation<void, any, DeviceGroupUpdateDTO, any>((d: DeviceGroupUpdateDTO) => store.api.deviceGroupApi.putDeviceGroup({ id: d.id!, deviceGroupUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceGroup', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceGroup', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceGroupDTO>(['deviceGroup', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceGroup', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['deviceGroup', variables.id])
        },
    })
}


export const useDeleteDeviceGroupMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation((d: string) => store.api.deviceGroupApi.deleteDeviceGroup({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['deviceGroups'])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['deviceGroups'])

            // Optimistically update to the new value
            queryClient.setQueryData<DeviceGroupDTO[]>(['deviceGroups'], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['deviceGroups'], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['deviceGroups'])
        },
    })
}

class DeviceGroupStore {
    rootStore: RootStore
    @observable empty: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }


    /*
        @action deviceGroupTableReport(reportType: string, format: ) {
    
            this.rootStore.api.reportApi.deviceGroupTableReport({
                format: format
            }).then((data) => {
                if (data) {
                    saveAs(data, i18n.t("reports.tablo." + reportType) + " " + moment().format("LLL") + (format === .Pdf ? ".pdf" : ".xlsx"))
                }
            })
        }*/
}

export default DeviceGroupStore