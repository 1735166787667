import { grey } from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import baseTheme from './base';


const darkTheme = createMuiTheme({
    props: {

    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: "16px"
            }
        }
    },
    palette: {
        primary: {
            main: '#2e3a48',
        },
        secondary: {
            main: '#7b78ff',
        },
        type: "dark",
        background: {
            default: '#536175',
            paper: "#1a2634",
            tree: "#74859e"
        },

    }
}, baseTheme)

export default darkTheme