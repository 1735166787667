import { observable, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { GetUsersRequest, UserDTO, UserPostDTO, UserUpdateDTO } from '../../api';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useStore } from '../../StoreProvider';

export const useUserList = (options?: (UseQueryOptions<UserDTO[]>)) => {
    const store = useStore()
    const param: GetUsersRequest = {
        roles: store.dataStore.user.roleFilter.length === 0 ? undefined : store.dataStore.user.roleFilter
    }
    return useQuery<UserDTO[]>(['users', {
        roles: store.dataStore.user.roleFilter.length === 0 ? undefined : store.dataStore.user.roleFilter
    }], ({ queryKey }) => {
        const [_key, params] = queryKey
        return store.api.userApi.getUsers(params as any)
    }, options)
}

export const useAddUserMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()
    const param: GetUsersRequest = {
        roles: store.dataStore.user.roleFilter.length === 0 ? undefined : store.dataStore.user.roleFilter
    }
    return useMutation((d: UserPostDTO) => store.api.userApi.postUser({ userPostDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['users', param])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['users', param])

            // Optimistically update to the new value
            queryClient.setQueryData<UserDTO[]>(['users', param], old => [...old!, newO])

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['users', param], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['users', param])
        },
    })
}

export const useUpdateUserMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()

    return useMutation<void, any, UserUpdateDTO, any>((d: UserUpdateDTO) => store.api.userApi.putUser({ id: d.id!, userUpdateDTO: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['user', newO.id])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(['user', newO.id])

            // Optimistically update to the new value
            queryClient.setQueryData<UserDTO>(['user', newO.id], newO)

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(['user', newO.id], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: (data, error, variables) => {
            queryClient.invalidateQueries(['users'])
        },
    })
}

export const useDeleteUserMutation = () => {
    const store = useStore()
    const queryClient = useQueryClient()
    const param: GetUsersRequest = {
        roles: store.dataStore.user.roleFilter.length === 0 ? undefined : store.dataStore.user.roleFilter
    }
    return useMutation((d: string) => store.api.userApi.deleteUser({ id: d }), {
        // When mutate is called:
        onMutate: async newO => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(["users", param])

            // Snapshot the previous value
            const previousO = queryClient.getQueryData(["users", param])

            // Optimistically update to the new value
            queryClient.setQueryData<UserDTO[]>(["users", param], old => {
                let a = old!.filter(t => t.id !== newO)
                return a
            })

            // Return a context object with the snapshotted value
            return { previousO }
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newO, context: any) => {
            queryClient.setQueryData(["users", param], context.previousO)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(["users", param])
        },
    })
}
class UserStore {
    rootStore: RootStore
    @observable roleFilter: string[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }
}

export default UserStore