/* tslint:disable */
/* eslint-disable */
/**
 * Cekip Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetEnviromentQualityDataRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    xTenantId?: string;
}

export interface GetPolygonsRequest {
    xTenantId?: string;
}

/**
 * EnviromentQualityDataApi - interface
 * 
 * @export
 * @interface EnviromentQualityDataApiInterface
 */
export interface EnviromentQualityDataApiInterface {
    /**
     * 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnviromentQualityDataApiInterface
     */
    getEnviromentQualityDataRaw(requestParameters: GetEnviromentQualityDataRequest): Promise<runtime.ApiResponse<object>>;

    /**
     */
    getEnviromentQualityData(requestParameters: GetEnviromentQualityDataRequest): Promise<object>;

    /**
     * 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnviromentQualityDataApiInterface
     */
    getPolygonsRaw(requestParameters: GetPolygonsRequest): Promise<runtime.ApiResponse<object>>;

    /**
     */
    getPolygons(requestParameters: GetPolygonsRequest): Promise<object>;

}

/**
 * 
 */
export class EnviromentQualityDataApi extends runtime.BaseAPI implements EnviromentQualityDataApiInterface {

    /**
     */
    async getEnviromentQualityDataRaw(requestParameters: GetEnviromentQualityDataRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EnviromentQualityData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getEnviromentQualityData(requestParameters: GetEnviromentQualityDataRequest): Promise<object> {
        const response = await this.getEnviromentQualityDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPolygonsRaw(requestParameters: GetPolygonsRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/EnviromentQualityData/polygons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPolygons(requestParameters: GetPolygonsRequest): Promise<object> {
        const response = await this.getPolygonsRaw(requestParameters);
        return await response.value();
    }

}
